import { ToolButtonKey } from '../shared/tools';
import useToolsButtonsList from './useToolsButtonsList';

const useMainToolsMenu = () => {
	const { getToolsButtonsListFiltered } = useToolsButtonsList();
	const keysToHide: ToolButtonKey[] = ['drafts', 'textart'];

	const sections = [
		{
			key: 'main-tools',
			buttons: getToolsButtonsListFiltered({
				filterIn: { type: 'tool', key: ['design-elements'] },
				filterOut: { key: keysToHide },
			}),
		},
	];

	return { sections };
};

export default useMainToolsMenu;
