import React, { FC, useContext, useEffect, useState } from 'react';
import { darken, lighten, transparentize } from 'polished';
import styled, { css, ThemeContext } from 'styled-components';
import { useZakekeTranslations } from '@zakeke/zakeke-customizer-react';

import { MOBILE_BREAKPOINT_PX } from '../../../shared/helpers';
import { ReactComponent as DeleteIcon } from '../../../shared/icons/delete.svg';
import { ReactComponent as RecolorIcon } from '../../../shared/icons/recolor.svg';
import { Icon, Stack } from '../../globals';
import { PrimaryButton } from '../button';
import Spinner from '../spinner';
import LoadMore, { GalleryLoaderBlock } from './loadMore';
import { useAppStore } from '../../../state/store';
import { usePrintMethodForSide } from '../../../hooks/usePrintMethods';
import Skeleton from '../skeleton';
import { useAvailableToolsForItem } from '../../../hooks/useAvailableTools';

// #region styled-components

const GalleryContainer = styled.div<{ $horizontalPad?: boolean; $compactMode?: boolean }>`
	flex: 1;
	min-height: 0;
	overflow-y: auto;

	${(props) =>
		props.$compactMode &&
		css`
			overflow-x: auto;
			overflow-y: hidden;
			padding-left: 0;
			padding-bottom: 20px;
		`}

	${(props) =>
		props.$horizontalPad &&
		css`
			padding-left: ${props.theme.spacing.horizontalPadder};
			padding-right: ${props.theme.spacing.horizontalPadder};
			@media screen and (max-width: ${MOBILE_BREAKPOINT_PX}) {
				padding-left: ${props.theme.spacing.horizontalPadderMobile};
				padding-right: ${props.theme.spacing.horizontalPadderMobile};
			}
		`}
`;

const OverlayContainer = styled.li`
	display: block;
	position: absolute;
	inset: 0;
	background-color: ${(props) => transparentize(0.1, darken(0.025, props.theme.colors.backgroundMain))};
	z-index: 10;
	height: 100%;
`;

const GalleryList = styled.ul<{
	$galleryImageWidth: number;
	$viewAsList?: boolean;
	$compact?: boolean;
}>`
	display: grid;
	gap: 16px;
	margin: 0;
	padding: 0;
	list-style: none;

	${(props) =>
		props.$compact &&
		css`
			display: flex;
			gap: 8px;
		`}

	&:has(${OverlayContainer}) {
		position: relative;
	}

	${(props) =>
		props.$viewAsList
			? css`
					grid-template-columns: repeat(auto-fill, minmax(700px, 1fr));
					@media screen and (max-width: ${MOBILE_BREAKPOINT_PX}) {
						grid-template-columns: 1fr;
					}
			  `
			: css`
					grid-template-columns: repeat(auto-fill, minmax(${props.$galleryImageWidth}px, 1fr));
			  `}
`;

const GalleryItemContainer = styled.div<{
	$galleryImageHeight: number;
	$galleryImageBlockPadding: number;
	$galleryImageInlinePadding: number;
	$compact?: boolean;
}>`
	width: 100%;
	margin-bottom: 4px;
	position: relative;
	isolation: isolate;

	${(props) => `
		height: ${props.$compact ? 80 : props.$galleryImageHeight}px;
	`}

	&:before,
	&:after {
		display: block;
		content: '';
		position: absolute;
		top: 0;
		z-index: -1;
		width: 100%;
		height: 100%;
		border-radius: calc(${(props) => props.theme.measures.borderRadius} * 4);
		background-color: ${(props) => props.theme.colors.backgroundPreview};
	}
	img,
	svg {
		width: 100%;
		height: 100%;
		${(props) => css`
			padding: ${props.$galleryImageBlockPadding}px ${props.$galleryImageInlinePadding}px;
		`}
		object-fit: contain;
		border-radius: calc(${(props) => props.theme.measures.borderRadius} * 4);
		border: 1px solid ${(props) => props.theme.colors.backgroundPreview};

		${(props) =>
			props.$compact &&
			css`
				width: 80px;
				height: 80px;
			`};
	}
`;

const GalleryItemStyled = styled.li<{ $viewAsList?: boolean; $compact?: boolean }>`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	cursor: pointer;
	position: relative;

	${(props) =>
		props.$compact &&
		css`
			width: 80px;
			height: 80px;
		`};

	${(props) =>
		props.$viewAsList &&
		css`
			gap: 16px;
			flex-direction: row;
			${GalleryItemContainer} {
				height: 48px !important;
				width: 100px;
				margin: 0;
			}
		`};

	@media (hover) {
		&:hover {
			${GalleryItemContainer} {
				img,
				svg {
					border-color: ${(props) => props.theme.colors.primary};
				}
			}
		}
	}
`;

const GalleryItemName = styled.span`
	font-size: 12px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	display: block;
	width: 100%;
`;

const GalleryItemDelete = styled(Icon)`
	position: absolute;
	height: 28px;
	width: 28px;
	top: 8px;
	right: 8px;
	padding: 4px;
	cursor: pointer;
	color: ${(props) => props.theme.colors.iconMain};
	background-color: ${(props) => props.theme.colors.backgroundMain};
	border-radius: 50%;

	@media (hover) {
		&:hover {
			color: ${(props) => props.theme.colors.primary};
		}
	}
`;

const GalleryErrorMessage = styled.div`
	width: 100%;
	text-align: center;
	padding: 20px;
	color: ${(props) => props.theme.colors.error};
`;

const StyledSpinner = styled(Spinner)`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${(props) => props.theme.colors.backgroundMain};
	opacity: 0.5;
	z-index: 10;
`;

const ProcessingRow = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -120%);
	display: flex;
	align-items: center;
	gap: 8px;
`;

const RecolorIconContainer = styled(Icon)<{ $compactMode?: boolean }>`
	position: absolute;
	width: 22px;
	height: 22px;
	padding: 1px;
	bottom: 32px;
	right: 8px;
	z-index: 1;
	background-color: ${({ theme }) => theme.colors.backgroundMain};
	border-radius: 50%;

	${({ $compactMode }) =>
		$compactMode &&
		css`
			bottom: 4px;
			right: 4px;
		`}
`;

// #endregion styled-components

// #region Overlay

const Overlay: FC<{
	show?: boolean;
}> = ({ show = false }) => {
	// to prevent mouse touc end to select element behind the overlay.
	const [showOverlay, setShowOverlay] = useState(show);

	useEffect(() => {
		setShowOverlay(show);
	}, [show, setShowOverlay]);

	return <>{showOverlay && <OverlayContainer />}</>;
};
// #endregion Overlay

// #region GalleryProps

export interface GalleryItem<T> {
	key: number | string;
	previewUrl: string;
	label: string;
	reference: T;
	isFromSeller?: boolean;
}

const GalleryItemLoaderProgress = styled.progress`
	width: 100%;
	height: 4px;
	border-radius: 4px;
	margin-bottom: 4px;
	&[value]::-webkit-progress-bar {
		border-radius: 4px;
		background-color: ${(props) => props.theme.colors.backgroundSecondary};
	}
	&[value]::-webkit-progress-value {
		border-radius: 4px;
		background-color: ${(props) => props.theme.colors.primary};
	}
`;

type GalleryItemLoaderProps = {
	compactMode?: boolean;
	viewAsList?: boolean;
	galleryImageHeight: number;
	galleryImageBlockPadding: number;
	galleryImageInlinePadding: number;
	progress?: number;
};
const GalleryItemLoader: FC<GalleryItemLoaderProps> = ({
	compactMode,
	viewAsList,
	galleryImageHeight,
	galleryImageBlockPadding,
	galleryImageInlinePadding,
	progress,
}) => {
	const { T } = useZakekeTranslations();
	return (
		<GalleryItemStyled $compact={compactMode} $viewAsList={viewAsList}>
			<GalleryItemContainer
				$galleryImageHeight={compactMode ? 80 : galleryImageHeight}
				$galleryImageBlockPadding={galleryImageBlockPadding}
				$galleryImageInlinePadding={galleryImageInlinePadding}
				$compact={compactMode}
			>
				<Skeleton variant='rectangular' width={compactMode ? '80px' : '100%'} height='100%' />
			</GalleryItemContainer>
			<GalleryItemName>
				<ProcessingRow>
					{!compactMode && <span>{progress === 100 ? T._('Processing') : T._('Uploading')}...</span>}
					<Spinner small />
				</ProcessingRow>
				<GalleryItemLoaderProgress value={progress} max={100} />
			</GalleryItemName>
		</GalleryItemStyled>
	);
};

export type GalleryNewItemLoaderOptions = {
	progress: number;
};
export interface GalleryProps<T> {
	items: GalleryItem<T>[];
	customPreview?: (item: GalleryItem<T>) => React.ReactNode;
	onItemSelected: (item: GalleryItem<T>) => void;
	onItemDelete?: (item: GalleryItem<T>) => Promise<void>;
	horizontalPad?: boolean;
	isLoading?: boolean;
	hasMore?: boolean;
	onLoadMore?: () => Promise<void> | undefined;
	onRetry?: () => void;
	hasError?: boolean;
	errorMessage?: React.ReactNode;
	galleryImageWidth?: number;
	galleryImageHeight?: number;
	galleryImageBlockPadding?: number;
	galleryImageInlinePadding?: number;
	viewAsList?: boolean;
	compactMode?: boolean;
	setCompactMode?: (mode: boolean) => void;
	showOverlay?: boolean;
	newItemLoader?: boolean | GalleryNewItemLoaderOptions;
}

const Gallery = <Data,>({
	items,
	customPreview,
	onItemSelected,
	onItemDelete,
	horizontalPad,
	isLoading,
	hasMore,
	onLoadMore,
	onRetry,
	hasError,
	errorMessage,
	galleryImageWidth = 120,
	galleryImageHeight = 120,
	galleryImageBlockPadding = 8,
	galleryImageInlinePadding = 8,
	viewAsList = false,
	compactMode,
	showOverlay = false,
	newItemLoader,
}: GalleryProps<Data>) => {
	// #endregion GalleryProps

	// #region Gallery render

	const { T } = useZakekeTranslations();
	const [selectedSideId] = useAppStore((x) => [x.selectedSideId]);
	const printMethod = usePrintMethodForSide(selectedSideId);

	const getAvailableTools = useAvailableToolsForItem();
	const availableTools = getAvailableTools();

	return (
		<GalleryContainer $horizontalPad={horizontalPad} $compactMode={compactMode}>
			{isLoading && <StyledSpinner />}
			<GalleryList $galleryImageWidth={galleryImageWidth} $viewAsList={viewAsList} $compact={compactMode}>
				<Overlay show={!isLoading && !hasError && showOverlay} />
				{newItemLoader && (
					<GalleryItemLoader
						compactMode={compactMode}
						viewAsList={viewAsList}
						galleryImageHeight={galleryImageHeight}
						galleryImageBlockPadding={galleryImageBlockPadding}
						galleryImageInlinePadding={galleryImageInlinePadding}
						progress={typeof newItemLoader === 'object' ? newItemLoader.progress : undefined}
					/>
				)}
				{items.map((item, idx) => {
					const itemRef = item.reference as any;
					return (
						<GalleryItemStyled
							$compact={compactMode}
							key={item.key}
							onClick={() => {
								onItemSelected(item);
							}}
							$viewAsList={viewAsList}
						>
							{itemRef &&
								itemRef.type === 'Vector' &&
								!itemRef.containsRaster &&
								printMethod.isSvgRecolorEnabled &&
								printMethod.hasColorLimit !== true &&
								availableTools.hasPrintTypePreviewEffect !== true && (
									<RecolorIconContainer $compactMode={compactMode}>
										<RecolorIcon />
									</RecolorIconContainer>
								)}
							<GalleryItemContainer
								$compact={compactMode}
								$galleryImageHeight={galleryImageHeight}
								$galleryImageBlockPadding={galleryImageBlockPadding}
								$galleryImageInlinePadding={galleryImageInlinePadding}
							>
								{customPreview?.(item) ?? <img src={item.previewUrl} alt='' />}
							</GalleryItemContainer>
							<GalleryItemName>{item.label}</GalleryItemName>
							{onItemDelete && (
								<GalleryItemDelete
									onClick={(e) => {
										e.stopPropagation();
										onItemDelete(item);
									}}
								>
									<DeleteIcon />
								</GalleryItemDelete>
							)}
						</GalleryItemStyled>
					);
				})}
				{!hasError && hasMore && onLoadMore && (
					<LoadMore isLoading={isLoading} onLoadMore={onLoadMore} $galleryImageHeight={galleryImageHeight} />
				)}
			</GalleryList>
			{hasError && (
				<Stack>
					<GalleryErrorMessage>{errorMessage || 'Generic Error'}</GalleryErrorMessage>
					{onRetry && <PrimaryButton onClick={onRetry}>{T._('Retry')}</PrimaryButton>}
				</Stack>
			)}
		</GalleryContainer>
	);
};

export const GalleryLoader: FC<{
	horizontalPad?: boolean;
	galleryImageWidth?: number;
	galleryImageHeight?: number;
}> = ({ horizontalPad, galleryImageWidth = 120, galleryImageHeight = 120 }) => {
	const themeProps = useContext(ThemeContext);
	const backColor = themeProps ? darken(0.035, themeProps.colors.backgroundMain) : '#f5f6f7';
	const foreColor = themeProps ? lighten(0.01, themeProps.colors.backgroundMain) : '#eee';

	return (
		<GalleryContainer $horizontalPad={horizontalPad}>
			<GalleryList $galleryImageWidth={galleryImageWidth}>
				<GalleryLoaderBlock
					$galleryImageHeight={galleryImageHeight}
					$backgroundColor={backColor}
					$foregroundColor={foreColor}
				/>
				<GalleryLoaderBlock
					$galleryImageHeight={galleryImageHeight}
					$backgroundColor={backColor}
					$foregroundColor={foreColor}
				/>
				<GalleryLoaderBlock
					$galleryImageHeight={galleryImageHeight}
					$backgroundColor={backColor}
					$foregroundColor={foreColor}
				/>
				<GalleryLoaderBlock
					$galleryImageHeight={galleryImageHeight}
					$backgroundColor={backColor}
					$foregroundColor={foreColor}
				/>
				<GalleryLoaderBlock
					$galleryImageHeight={galleryImageHeight}
					$backgroundColor={backColor}
					$foregroundColor={foreColor}
				/>
				<GalleryLoaderBlock
					$galleryImageHeight={galleryImageHeight}
					$backgroundColor={backColor}
					$foregroundColor={foreColor}
				/>
			</GalleryList>
		</GalleryContainer>
	);
};
// #endregion Gallery render

export default Gallery;
