import styled, { css } from 'styled-components';
import { SMALLDESK_BREAKPOINT_PX } from '../../../shared/helpers';

export const UiLayout = styled.div<{ $isMobile?: boolean; $isPreview3DOpened?: boolean }>`
	display: grid;
	width: 100%;
	height: 100%;
	background-color: ${(props) => props.theme.colors.backgroundCanvas};
	color: ${(props) => props.theme.colors.textMain};

	${({ theme: { desktopLayout }, $isPreview3DOpened, $isMobile }) =>
		!$isMobile &&
		css`
			${desktopLayout.footerPosition === 'top' &&
			css`
				grid-template-rows: auto minmax(0, 1fr);
			`}
			${desktopLayout.footerPosition === 'bottom' &&
			css`
				grid-template-rows: minmax(0, 1fr) auto;
			`};
			${desktopLayout.menuPosition === 'right' &&
			css`
				grid-template-columns: ${$isPreview3DOpened ? ' minmax(200px, 28%)' : '0'} 1fr auto;
			`}
			${desktopLayout.menuPosition === 'left' &&
			css`
				grid-template-columns: auto 1fr ${$isPreview3DOpened ? ' minmax(200px, 28%)' : '0'};
			`}
			grid-template-areas:
				${desktopLayout.footerPosition === 'top' && css`'footer footer footer'`}
				${desktopLayout.menuPosition === 'right' && css`'preview3d customizer menuandtoolbar'`}
				${desktopLayout.menuPosition === 'left' && css`'menuandtoolbar customizer preview3d'`}
				${desktopLayout.footerPosition === 'bottom' && css`'footer footer footer'`};
		`}

	${(props) =>
		props.$isMobile &&
		css`
			grid-template-columns: 1fr;
			grid-template-rows: minmax(0, 1fr) auto;
			grid-template-areas:
				'customizer'
				'productinfo'
				'footer';
		`};
`;

export const UiMenuAndToolbarContainer = styled.div<{ $expanded?: boolean }>`
	position: relative;
	display: grid;
	grid-area: menuandtoolbar;
	grid-template-rows: 68px minmax(0, 1fr);
	column-gap: 16px;
	z-index: 1;

	${({ theme: { desktopLayout }, $expanded }) =>
		css`
			${desktopLayout.footerPosition === 'bottom' &&
			css`
				padding-block-start: 0;
				padding-block-end: 16px;
			`}

			${desktopLayout.footerPosition === 'top' &&
			css`
				padding-block-start: 16px;
				padding-block-end: 0;
			`}

			${desktopLayout.menuPosition === 'right' &&
			css`
				grid-template-areas:
					'pages tools-actions'
					'pages tools';
				grid-template-columns: minmax(425px, 200px) auto;
				padding-inline-end: 30px;

				@media screen and (max-width: ${SMALLDESK_BREAKPOINT_PX}) {
					padding-inline-end: 20px;
				}

				${!$expanded &&
				css`
					@media screen and (max-width: ${SMALLDESK_BREAKPOINT_PX}) {
						grid-template-columns: minmax(400px, 200px) auto;
					}
				`}
			`}

			${desktopLayout.menuPosition === 'left' &&
			css`
				grid-template-areas:
					'tools-actions pages'
					'tools pages';
				grid-template-columns: auto minmax(425px, 200px);
				padding-inline-start: 30px;

				@media screen and (max-width: ${SMALLDESK_BREAKPOINT_PX}) {
					padding-inline-start: 20px;
				}

				${!$expanded &&
				css`
					@media screen and (max-width: ${SMALLDESK_BREAKPOINT_PX}) {
						grid-template-columns: auto minmax(400px, 200px);
					}
				`}
			`}
		
		${$expanded &&
			css`
				grid-column-start: customizer;
				grid-column-end: menuandtoolbar;
				z-index: 2;

				${desktopLayout.menuPosition === 'right' &&
				css`
					grid-template-columns: 1fr auto;
					padding-inline-start: 30px;
					@media screen and (max-width: ${SMALLDESK_BREAKPOINT_PX}) {
						padding-inline-start: 20px;
					}
				`}
				${desktopLayout.menuPosition === 'left' &&
				css`
					grid-template-columns: auto 1fr;
					padding-inline-end: 30px;
					@media screen and (max-width: ${SMALLDESK_BREAKPOINT_PX}) {
						padding-inline-end: 20px;
					}
				`}
			`};
		`}
`;

export const UiMenuAndToolbarToolsActionsContainer = styled.div`
	position: relative;
	grid-area: tools-actions;
	display: flex;
	align-items: flex-end;
	padding-block-end: 8px;
	overflow: auto;
	background-color: ${(props) => props.theme.colors.backgroundSecondary};
	${({ theme: { desktopLayout, measures } }) => css`
		${desktopLayout.footerPosition === 'top' &&
		css`
			border-top: 0;
			border-radius: calc(${measures.borderRadius} * 4) calc(${measures.borderRadius} * 4) 0 0;
		`}
	`}
`;

export const UiMenuAndToolbarPagesContainer = styled.div`
	position: relative;
	grid-area: pages;
`;

export const UiMenuAndToolbarToolsContainer = styled.div`
	position: relative;
	grid-area: tools;
	display: flex;
	background-color: ${(props) => props.theme.colors.backgroundSecondary};
	border-color: ${(props) => props.theme.colors.borders};
	border-style: solid;
	border-width: ${(props) => props.theme.panels.borderWidth};
	border-inline: 0;
	overflow: auto;
	${({ theme: { desktopLayout, measures } }) => css`
		${desktopLayout.footerPosition === 'bottom' &&
		css`
			border-top: 0;
			border-radius: 0 0 calc(${measures.borderRadius} * 4) calc(${measures.borderRadius} * 4);
		`}
		${desktopLayout.footerPosition === 'top' &&
		css`
			border-top: 0;
			border-bottom: 0;
			border-radius: 0;
		`}
	`}
`;

export const UiLayoutCustomizerContainer = styled.div<{ $expanded?: boolean }>`
	position: relative;
	grid-area: customizer;
	z-index: 2;
	max-width: 30000px;
	max-height: 30000px;
	${(props) =>
		props.$expanded &&
		css`
			z-index: 1;
		`};
`;
