import {
	useZakekeCollages,
	useZakekeEditMode,
	useZakekeHelpers,
	useZakekePrice,
	useZakekeProduct,
	useZakekeSettings,
	useZakekeOutsideZoom,
	useZakekeMessagePerProduct,
	useZakekeStatus,
	useZakekeInitialVariant,
	useZakekeTranslationsLocalization,
	ZakekeMatrix,
	useZakekeClipartsPaginated,
	useZakekeIntegrationScripts,
	useZakekeAccessibilityScripts,
} from '@zakeke/zakeke-customizer-react';
import { useEffect, useState } from 'react';

import NameNumberDialog from '../components/dialogs/nameNumberDialog';
import TemplateSelectionDialog from '../components/dialogs/templateSelectionDialog';
import VariantSelectionDialog from '../components/dialogs/variantSelectionDialog';
import { CustomizerElement, EventMessageType, isImageElement } from '../components/interfaces';
import { MessageDialog, useDialogManager } from '../components/widgets/dialogs';
import config from '../shared/config';
import { isMobile, isMobileMatchMedia } from '../shared/helpers';
import { getCoverBoxBounds, boxCenter } from '../shared/helpers.box';
import { setMatrixLinearResize } from '../shared/helpers.matrix';
import { useAppStore, useGetItems, useRemoveItem, useSetItems, useUpdateItem } from '../state/store';
import useDesignLoader from './useDesignLoader';
import useSelectedVariant from './useSelectedVariant';
import useTemplateLoader from './useTemplateLoader';
import useVariantChanger from './useVariantChanger';
import useUndoRedo from './useUndoRedo';
import useEffectOnceWhen from './useEffectOnceWhen';
import { useTheme } from 'styled-components';
import useUIModeSettings from './useUIModeSettings';

const useSyncIsFullScreenActive = () => {
	const setIsFullscreenActive = useAppStore((x) => x.setIsFullscreenActive);
	// Set if fullscreen or not
	useEffect(() => {
		const cb = () => {
			setIsFullscreenActive(!!document.fullscreenElement);
		};
		document.addEventListener('fullscreenchange', cb);
		return () => window.removeEventListener('fullscreenchange', cb);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
};

const useLoadTranslations = () => {
	const settings = useZakekeSettings();
	// load translations scripts
	useZakekeTranslationsLocalization(settings.culture, config.avaibleLanguages);
};

const useLoadAdditionalScripts = () => {
	// load accessibility scripts, if available
	useZakekeAccessibilityScripts();
	// load integrations scripts, if available
	useZakekeIntegrationScripts();
};

const useSyncPriceFormatter = () => {
	const setPriceFormatter = useAppStore((x) => x.setPriceFormatter);
	const product = useZakekeProduct();
	const [, currency, culture] = useZakekePrice();
	// Create the price formatter
	useEffect(() => {
		if (product) {
			const formatter = new Intl.NumberFormat(culture, {
				style: 'currency',
				currency: currency,
				currencyDisplay: 'symbol',
			});

			setPriceFormatter(formatter);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currency, culture, product]);
};

const useInitFacebook = () => {
	// Init facebook
	useEffect(() => {
		const script1 = document.createElement('script');
		script1.innerHTML = `
            window.fbAsyncInit = function() {
                FB.init({
                appId            : '${process.env.REACT_APP_FACEBOOK_APP_ID}',
                autoLogAppEvents : true,
                xfbml            : true,
                version          : 'v15.0'
                });
            };
        `;

		const script2 = document.createElement('script');
		script2.src = 'https://connect.facebook.net/en_US/sdk.js';
		script2.crossOrigin = 'anonymous';

		document.body.appendChild(script1);
		document.body.appendChild(script2);

		return () => {
			script1.remove();
			script2.remove();
		};
	}, []);
};

const useResetUndoRedoOnVariantChange = () => {
	const selectedVariantId = useAppStore((x) => x.selectedVariantId);
	const { resetUndoRedo } = useUndoRedo();
	//reset undo/redo stack on variant change
	useEffect(() => {
		resetUndoRedo();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedVariantId]);
};

const useRecenterCanvasOn3DPreviewStateChange = () => {
	const isPreview3DOpened = useAppStore((x) => x.isPreview3DOpened);
	const preview3DMode = useAppStore((x) => x.preview3DMode);
	// Re-center canvas view when opening/closing 3d view
	useEffect(() => {
		// Trigger a window resize
		window.dispatchEvent(new Event('resize'));
	}, [isPreview3DOpened, preview3DMode]);
};

const useZoomOnSelectedSideChange = () => {
	const {
		zoom: { zoomPercentageIncrement },
	} = useTheme();
	const selectedSideId = useAppStore((x) => x.selectedSideId);
	const setOutsideZoom = useZakekeOutsideZoom();
	// if initial zoom form BO set it here
	useEffect(() => {
		if (zoomPercentageIncrement && selectedSideId) {
			setOutsideZoom(zoomPercentageIncrement / 100, selectedSideId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [zoomPercentageIncrement, selectedSideId]);
};

const useChangePageOnSelectedItemGuidChange = () => {
	const preventPageChangeOnItemChange = useAppStore((x) => x.preventPageChangeOnItemChange);
	const setMenuPage = useAppStore((x) => x.setMenuPage);
	const setMobileMenuPage = useAppStore((x) => x.setMobileMenuPage);
	const openEditMenuPageForItem = useAppStore((x) => x.openEditMenuPageForItem);
	const openEditMobileMenuPageForItem = useAppStore((x) => x.openEditMobileMenuPageForItem);
	const selectedItemGuid = useAppStore((x) => x.selectedItemGuid);
	const getItems = useGetItems();

	// Change page on item selection changed
	useEffect(() => {
		if (preventPageChangeOnItemChange) {
			return;
		}

		const item = selectedItemGuid ? getItems().find((x) => x.guid === selectedItemGuid) : null;

		if (isMobile()) {
			if (item) {
				openEditMobileMenuPageForItem(item);
				return;
			}
			setMobileMenuPage({ page: 'none' });
			return;
		}
		if (item) {
			openEditMenuPageForItem(item, true);
			return;
		}
		setMenuPage({ page: 'design-elements' });

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedItemGuid]);
};

const useAdaptItemOnCollageChange = () => {
	const preventCollageElementsReadapt = useAppStore((x) => x.preventCollageElementsReadapt);
	const setPreventCollageElementsReadapt = useAppStore((x) => x.setPreventCollageElementsReadapt);
	const sidesCollages = useAppStore((x) => x.sidesCollages);
	const selectedSideId = useAppStore((x) => x.selectedSideId);
	const collages = useZakekeCollages();
	const getItems = useGetItems();
	const updateItem = useUpdateItem();
	const removeItem = useRemoveItem();
	const { getCollageBoxBounds, getRenderedItemBounds } = useZakekeHelpers();
	// Adapt items when collage change
	useEffect(() => {
		setTimeout(() => {
			if (!preventCollageElementsReadapt) {
				try {
					for (const [sideId, collageId] of sidesCollages) {
						if (sideId !== selectedSideId) continue;
						const items = getItems();
						if (collageId !== null) {
							const sideItems = items.filter((x) => x.sideId === sideId);
							const collage = collages.find((x) => x.id === collageId)!;

							let collageBoxId = 0;
							const itemsToRemove: string[] = [];

							for (const item of sideItems) {
								if (isImageElement(item) && item.sideId === sideId) {
									if (
										collageBoxId >= collage.boxesCount &&
										item.constraints?.canDelete !== false &&
										item.maskShapeId === null
									) {
										itemsToRemove.push(item.guid);
										continue;
									}

									if (
										item.isBackgroundForArea ||
										item.constraints?.isAlwaysOnTop === true ||
										item.constraints?.isAlwaysOnBottom === true ||
										item.constraints?.canMove === false ||
										item.maskShapeId != null
									)
										continue;

									const bounds = getCollageBoxBounds(sideId, item?.collageBoxId ?? collageBoxId);
									const itemBounds = getRenderedItemBounds(sideId, item.guid);

									/* eslint-disable no-loop-func */
									updateItem(
										item.guid,
										(prev) => {
											const result: Partial<CustomizerElement> = {
												collageBoxId: item.collageBoxId ?? collageBoxId,
											};
											console.log({ result });
											if (bounds) {
												const canResize = item.constraints?.canResize !== false;
												const { positionX, positionY } = boxCenter(bounds);
												result.positionX = positionX;
												result.positionY = positionY;
												if (canResize && itemBounds) {
													const { width, height } = getCoverBoxBounds(
														bounds,
														itemBounds.bounds,
													);
													result.width = width;
													result.height = height;
												}
												if (item.matrix) {
													let newMatrix;
													if (canResize && result.width && result.height && itemBounds) {
														newMatrix = setMatrixLinearResize(
															item.matrix,
															result.width / itemBounds.bounds.width,
															itemBounds?.transformsData.position,
														);
													}
													newMatrix = (newMatrix ?? item.matrix)
														.clone()
														.prepended(
															new ZakekeMatrix().translate(
																result.positionX - item.matrix.translation.x,
																result.positionY - item.matrix.translation.y,
															),
														);
													item.matrix = newMatrix;
												}
											}
											return result;
										},
										true,
									);
									collageBoxId++;
									/* eslint-enable no-loop-func */
								}
							}

							// Remove items in excess
							for (const guid of itemsToRemove) {
								removeItem(guid, true);
							}
						} else {
							for (const item of items) {
								if (isImageElement(item) && item.sideId === sideId) {
									updateItem(item.guid, () => ({ collageBoxId: null }), true);
								}
							}
						}
					}
				} catch {}
			}

			setPreventCollageElementsReadapt(false);
		}, 200);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sidesCollages]);
};

const useSyncMobileCustomizer = () => {
	const { setMobileCustomizer } = useZakekeHelpers();
	useEffect(() => {
		const onMobileChange = () => {
			setMobileCustomizer(isMobileMatchMedia.matches);
		};
		isMobileMatchMedia.addEventListener('change', onMobileChange);
		return () => isMobileMatchMedia.removeEventListener('change', onMobileChange);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
};

const useInitialLoadingSetup = () => {
	const [setIsBootCompleted, selectedVariantId, currentTemplate, setCurrentTemplate, setNameNumberSelection] =
		useAppStore((x) => [
			x.setIsBootCompleted,
			x.selectedVariantId,
			x.currentTemplate,
			x.setCurrentTemplate,
			x.setNameNumberSelection,
		]);

	const { showDialog } = useDialogManager();

	const getItems = useGetItems();
	const setItems = useSetItems();

	const [hasProcessedWelcomeMessage, setHasProcessedWelcomeMessage] = useState(false);
	const [isLoadingTemplate, setIsLoadingTemplate] = useState(false);

	const product = useZakekeProduct();
	const settings = useZakekeSettings();
	const { designId, isOrderDesignEditor, getNameAndNumberSelections } = useZakekeEditMode();
	const { isReady } = useZakekeStatus();
	const initialVariant = useZakekeInitialVariant();
	const selectedVariant = useSelectedVariant();
	const changeVariant = useVariantChanger();

	const { loadDesign } = useDesignLoader();
	const { loadTemplate, isTemplateValidForVariant, countTemplatesForVariant, getDefaultTemplateForVariant } =
		useTemplateLoader();
	const uiSettings = useUIModeSettings();

	const getMessagePerProduct = useZakekeMessagePerProduct();

	//FIX-TODO: start
	//awaiting for the BE change, in the meantime
	// we will use the cliparts provider and force and initial loading
	useZakekeClipartsPaginated(null, '');
	//FIX-TODO: end

	const getWelcomeMessage = async () => {
		// Welcome message searching for type and if isDefault true
		// with isDefault false is a product message
		const welcomeMessage = settings.eventMessages.find(
			(m) => m.eventID === EventMessageType.WelcomeMessage && m.isDefault === true,
		);
		const welcomeMessageGuest = settings.eventMessages.find(
			(m) => m.eventID === EventMessageType.WelcomeMessageGuest,
		);
		let message =
			settings.isGuestInStore && welcomeMessageGuest && welcomeMessageGuest.visible
				? welcomeMessageGuest
				: welcomeMessage;

		if (settings.hasEventMessagesByProduct) {
			// We use specific call to be sure that message is for product
			const messagesByProd = await getMessagePerProduct();
			if (messagesByProd.length > 0) {
				const welcomeMessage = messagesByProd.find((m) => m.eventID === EventMessageType.WelcomeMessage);
				if (welcomeMessage && welcomeMessage.visible) message = welcomeMessage;
			}
		}

		return message;
	};

	const showWelcomeMessage = async () => {
		const message = await getWelcomeMessage();
		if (message && message.visible && !uiSettings.avoidWelcomeMessage) {
			await new Promise<void>((resolve) =>
				showDialog(
					'welcome',
					<MessageDialog
						onClose={() => {
							resolve();
						}}
						message={<span dangerouslySetInnerHTML={{ __html: message!.description }} />}
					/>,
				),
			);
		}
		setHasProcessedWelcomeMessage(true);
	};

	// Initial loading
	useEffectOnceWhen(async () => {
		if (initialVariant) {
			// change variant before showing welcome message to fasten the load after the welcome message
			await Promise.all([
				changeVariant(initialVariant.id, {
					preventResetEnabled: true,
					preventAdapt: true,
				}),
				showWelcomeMessage(),
			]);
		}
		if (!initialVariant) {
			await showWelcomeMessage();
		}
		if (!initialVariant && !designId) {
			// If in edit mode, we will load the design variant
			showDialog('variant-selection', <VariantSelectionDialog mode='default' />);
		}
		if (designId) {
			await loadDesign(designId, isOrderDesignEditor);
			const nameNumberSelections = await getNameAndNumberSelections(isOrderDesignEditor);

			setNameNumberSelection(nameNumberSelections);
			setIsBootCompleted(true);

			if (nameNumberSelections.length > 0) showDialog('name-number', <NameNumberDialog />);
		}
	}, true);

	// load correct template if not in edit design mode
	// if first time, load the default template for the selected variant
	// when changing a variant, if the template is not valid for the new variant,
	// load the default template of the new variant or delete all the items if there is no default template
	useEffect(() => {
		if (!product || !selectedVariant || !isReady || isLoadingTemplate) return;

		// First time don't load template if we are in edit design
		if (!currentTemplate && designId) return;

		setIsLoadingTemplate(true);

		// If current template is not valid for selected variant, remove everything
		const isCurrentTemplateValid = currentTemplate
			? isTemplateValidForVariant(currentTemplate.templateId, selectedVariantId)
			: true;

		(async () => {
			if (getItems().length === 0 || !isCurrentTemplateValid) {
				const defaultTemplateIdForVariant =
					getDefaultTemplateForVariant(selectedVariantId) || product.defaultTemplateId;
				// If default template is different from current template, load it
				if (defaultTemplateIdForVariant && defaultTemplateIdForVariant !== currentTemplate?.templateId) {
					await loadTemplate(defaultTemplateIdForVariant);
				} else {
					// no default template, remove all items
					setItems(() => []);
					setCurrentTemplate(null);
				}
			}

			// If edit mode, boot completed is called in the other useEffect
			// else set true if no template or load template finished
			setIsBootCompleted(true);
			setIsLoadingTemplate(false);
		})();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [product, selectedVariant, isReady]);

	useEffectOnceWhen(() => {
		if (!product || !selectedVariant || !isReady || isLoadingTemplate || !hasProcessedWelcomeMessage) return;

		// First time don't load template if we are in edit design
		if (!currentTemplate && designId) return;

		if (countTemplatesForVariant(selectedVariantId) > 1 && product.showTemplateSelection) {
			showDialog('template-selection', <TemplateSelectionDialog />);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, !(!product || !selectedVariant || !isReady || isLoadingTemplate || !hasProcessedWelcomeMessage));
};

// const usePreventMobileCanvasError = () => {
// 	useEffect(() => {
// 		window.addEventListener("visibilitychange", () => {
// 			if (isMobile() && document.visibilityState !== "hidden") {
// 					setTimeout(() => {
// 						const element : HTMLDivElement|null = document.querySelector('#root');
// 						if (!element) return;
// 						element.style.height = (element.clientHeight + 1) + 'px';
// 						element.style.marginTop = '-1px';
// 						setTimeout(() => {
// 							element.style.height = '';
// 							element.style.marginTop = '';
// 						}, 500);
// 					}, 500);
// 			}
// 		});
// 	}, []);
// }

const useGlobalEvents = () => {
	useInitFacebook();
	useLoadTranslations();
	useLoadAdditionalScripts();
	useSyncIsFullScreenActive();
	useSyncPriceFormatter();
	useSyncMobileCustomizer();
	useResetUndoRedoOnVariantChange();
	useRecenterCanvasOn3DPreviewStateChange();
	useZoomOnSelectedSideChange();
	useChangePageOnSelectedItemGuidChange();
	useAdaptItemOnCollageChange();
	useInitialLoadingSetup();
	// usePreventMobileCanvasError();
};

export default useGlobalEvents;
