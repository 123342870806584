import { FC } from 'react';
import { HorizontalPadder, MenuPage } from './common';
import { Stack } from '../globals';
import Skeleton from '../widgets/skeleton';
import GridSkeleton from '../widgets/gridSkeleton';

const DefaultContent = () => (
	<Stack $gap={8}>
		<Skeleton variant='rectangular' width='100%' height='48px' />
		<Skeleton variant='rectangular' width='100%' height='48px' />
		<Skeleton variant='rectangular' width='100%' height='48px' />
		<Skeleton variant='rectangular' width='100%' height='48px' />
		<Skeleton variant='rectangular' width='100%' height='48px' />
	</Stack>
);

const UploadContent = () => {
	return (
		<Stack $gap={8}>
			<Skeleton variant='rectangular' width='100%' height='40px' />
			<Skeleton variant='rectangular' width='100%' height='40px' />
			<Skeleton variant='text' width='30%' fontSize='1em' />
			<GridSkeleton columns={2} />
		</Stack>
	);
};

const GalleryContent = () => {
	return (
		<Stack $gap={8}>
			<Stack $gap={8} $horizontal>
				<Skeleton variant='rectangular' width='50%' height='40px' />
				<Skeleton variant='rectangular' width='50%' height='40px' />
			</Stack>
			<GridSkeleton columns={2} />
		</Stack>
	);
};

const TabbedContent = () => {
	return (
		<Stack $gap={8}>
			<Stack $gap={8} $horizontal>
				<Skeleton variant='text' width='30%' fontSize='1em' />
				<Skeleton variant='text' width='30%' fontSize='1em' />
				<Skeleton variant='text' width='30%' fontSize='1em' />
			</Stack>
			<Skeleton variant='rectangular' width='100%' height='60px' />
			<Skeleton variant='rectangular' width='100%' height='60px' />
			<Skeleton variant='rectangular' width='100%' height='60px' />
		</Stack>
	);
};

export type MenuSkeletonVariant = 'default' | 'upload' | 'gallery' | 'tabbed';
export type MenuSkeletonProps = {
	variant?: MenuSkeletonVariant;
};
const MenuSkeleton: FC<MenuSkeletonProps> = ({ variant = 'default' }) => (
	<MenuPage title={<Skeleton variant='text' width='70%' fontSize='1.2em' />} hideCloseButton>
		<HorizontalPadder>
			{variant === 'default' && <DefaultContent />}
			{variant === 'upload' && <UploadContent />}
			{variant === 'gallery' && <GalleryContent />}
			{variant === 'tabbed' && <TabbedContent />}
		</HorizontalPadder>
	</MenuPage>
);

export default MenuSkeleton;
