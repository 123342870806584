/*
	utilità di calcolo legate ai Box
	// qui c'è un errore positionX e positionY sono i centri dei rettangoli, invece x e y sono gli angoli in alto a sinistra, qui sono usati come lo stesso tipo di dato.
 */

import { minBy } from 'lodash';
import { Rectangle } from '../components/interfaces';

// #region types
type radian = number;
type degree = number;
type Box = {
	width: number;
	height: number;
	positionX: number;
	positionY: number;
	rotation: degree;
};
// TODO : unificare questi tipi ai tipi in components/interfaces
// un box che contiene anche alias legati alla struttura di item
type BoxItem = Partial<Box> & Partial<Rectangle> & {
	width: number;
	height: number;
};
type BoxPointAsPaper = {
	x: number;
	y: number;
};
type BoxPointAsUI = {
	positionX: number;
	positionY: number;
};
type BoxPoint = BoxPointAsUI & BoxPointAsPaper;
// #endregion

// #region utility
// const fromRadiant2Degree = (radian: radian) :degeree => radian * 180 / Math.PI;
const fromDegreet2Radian = (deg: degree): radian => (deg / 180) * Math.PI;
const hasRotation = (deg: degree) => deg !== 0 || deg % 360 !== 0;
const boxItemClone = (box: BoxItem) : Box => ({
	...box,
	rotation: box.rotation ?? 0,
	positionX: box.positionX ?? box.x ?? 0,
	positionY: box.positionY ?? box.y ?? 0,
} as Box);

/**
 * returns the list of points of the box after rotation
 */
function boxPoinsRotate(box: Box): BoxPoint[] {
	const vertexes = [
		{
			positionX: box.positionX,
			positionY: box.positionY,
		},
		{
			positionX: box.positionX + box.width,
			positionY: box.positionY,
		},
		{
			positionX: box.positionX + box.width,
			positionY: box.positionY + box.height,
		},
		{
			positionX: box.positionX,
			positionY: box.positionY + box.height,
		},
	];
	const radAngle = fromDegreet2Radian(box.rotation || 0);
	return vertexes.map(({ positionX, positionY }) => {
		return {
			positionX: positionX * Math.cos(radAngle) - positionY * Math.sin(radAngle),
			positionY: positionX * Math.sin(radAngle) + positionY * Math.cos(radAngle),
			x: positionX * Math.cos(radAngle) - positionY * Math.sin(radAngle),
			y: positionX * Math.sin(radAngle) + positionY * Math.cos(radAngle),
		};
	});
}
/**
 * returns the box that contain rotated box or box itself if haven't rotation
 */
export function getBoxRotationWrapper(box: Box): Box {
	if (!hasRotation(box.rotation)) {
		return box;
	}
	const vertexes = boxPoinsRotate(box);
	const wrapperVertexes = vertexes.reduce((pre: null | Record<string, number>, curr: BoxPoint) => {
		if (!pre) {
			return {
				minH: curr.positionY,
				maxH: curr.positionY,
				minW: curr.positionX,
				maxW: curr.positionX,
			};
		}
		return {
			minH: Math.min(curr.positionY, pre.minH),
			maxH: Math.max(curr.positionY, pre.maxH),
			minW: Math.min(curr.positionX, pre.minW),
			maxW: Math.max(curr.positionX, pre.maxW),
		};
	}, null);
	return {
		width: wrapperVertexes!.maxW - wrapperVertexes!.minW,
		height: wrapperVertexes!.maxH - wrapperVertexes!.minH,
		positionX: wrapperVertexes!.minW,
		positionY: wrapperVertexes!.minH,
		rotation: 0,
	};
}
// #endregion

// #region export
/**
 * returns a box that let content to cover all container's area.
 * */
export function getCoverBoxBounds(containerBoxItem: BoxItem, contentBoxRatio: BoxItem | number): Pick<Box, 'width'|'height'> {
	const containerBox = getBoxRotationWrapper(boxItemClone(containerBoxItem));
	const boxRatio =
		typeof contentBoxRatio === 'object' ? contentBoxRatio.width / contentBoxRatio.height : contentBoxRatio;
	const angle =
		typeof contentBoxRatio === 'object' && 'rotation' in contentBoxRatio && contentBoxRatio.rotation
			? contentBoxRatio.rotation
			: 0;

	if (typeof contentBoxRatio !== 'object' || !hasRotation(angle)) {
		return {
			width: Math.max(containerBox.height * boxRatio, containerBox.width),
			height: Math.max(containerBox.height, containerBox.width / boxRatio),
		};
	}

	const rotateBox = getBoxRotationWrapper(boxItemClone(contentBoxRatio));
	const { width } = getCoverBoxBounds(containerBox, rotateBox);
	const scale = width / rotateBox.width;
	return {
		width: scale * contentBoxRatio.width,
		height: scale * contentBoxRatio.height,
	};
}

/**
 * returns a box that let content to fit in container' without cross borders, without rotation.
 * */
export function getFitNoAngleBoxBounds(containerBoxItem: BoxItem, contentBoxRatio: BoxItem | number): Pick<Box, 'width'|'height'> {
	const containerBox = boxItemClone(containerBoxItem);

	const boxRatio =
		typeof contentBoxRatio === 'object' ? contentBoxRatio.width / contentBoxRatio.height : contentBoxRatio;
	return {
		width: Math.min(containerBox.height * boxRatio, containerBox.width),
		height: Math.min(containerBox.height, containerBox.width / boxRatio),
	};
}

/**
 * returns the center of box as point.
 */
export function boxCenter(b: BoxItem): BoxPoint {
	const box = boxItemClone(b);

	return {
		positionX: box.positionX + box.width / 2,
		positionY: box.positionY + box.height / 2,
		x: box.positionX + box.width / 2,
		y: box.positionY + box.height / 2,
	};
}

export const getClosestArea = (areas: BoxItem[], startX: number, startY: number) => {
	if (areas.length === 0) return null;
	return minBy(areas, (a) => {
		const { positionX, positionY, } = boxCenter(a);
		return Math.sqrt((positionX - startX) ** 2 + (positionY - startY) ** 2);
	});
};
/**
 * return distance from a point and the straight lines pass by the box center and box sides
 *
*/
export const boxPointOrtagonalDistance = (b: BoxItem, point: BoxPoint|BoxPointAsUI) => {
	const box = boxItemClone(b);
	const center = boxCenter(box);

	return {
		center: {
			positionX: point.positionX - center.positionX,
			positionY: point.positionY - center.positionY,
			x: point.positionX - center.positionX,
			y: point.positionY - center.positionY,
		},
		left: point.positionX - box.positionX,
		right: point.positionX - (box.positionX + box.width),
		top: point.positionY - box.positionY,
		bottom: point.positionY - (box.positionY + box.height),
	};
}


export const isPointInBox = (point: BoxPointAsPaper, b: BoxItem, excludeBorder: boolean) => {
	const box = boxItemClone(b);

	if (excludeBorder) {
		return (
			box.positionX < point.x &&
			point.x < box.positionX + box.width &&
			box.positionY < point.y &&
			point.y < box.positionY + box.height
		);
	}

	return (
		box.positionX <= point.x &&
		point.x <= box.positionX + box.width &&
		box.positionY <= point.y &&
		point.y <= box.positionY + box.height
	);
}
// #endregion

export function rotatePoint(centerX: number, centerY: number, pointX: number, pointY: number, angleDegrees: number) {
	// Convert angle from degrees to radians
	const angleRadians = angleDegrees * (Math.PI / 180);

	// Translate point to origin
	const translatedX = pointX - centerX;
	const translatedY = pointY - centerY;

	// Perform rotation
	const rotatedX = translatedX * Math.cos(angleRadians) - translatedY * Math.sin(angleRadians);
	const rotatedY = translatedX * Math.sin(angleRadians) + translatedY * Math.cos(angleRadians);

	// Translate back to original position
	const finalX = rotatedX + centerX;
	const finalY = rotatedY + centerY;

	return { x: finalX, y: finalY };
}
