import { useAppStore } from '../../state/store';
import {
	useZakekeAddToCart,
	useZakekeDesignEditorMode,
	useZakekeTestingMode,
	useZakekeTranslations,
} from '@zakeke/zakeke-customizer-react';
import useProfanityFilter from '../../hooks/useProfanityFilter';
import Loading from '../widgets/loading';
import React, { useMemo } from 'react';
import useGlobalLoader from '../../hooks/useGlobalLoader';

const useAddToCartLoadingText = () => {
	const { T } = useZakekeTranslations();
	const testingMode = useZakekeTestingMode();
	const designEditorMode = useZakekeDesignEditorMode();
	return useMemo(
		() => {
			if (designEditorMode.enabled) {
				return [T._('Saving the design...')];
			}
			if (testingMode.enabled) {
				return [];
			}
			return [
				{
					text: T._('Adding the product to your cart...'),
					duration: 2000,
				},
				{
					text: T._('Almost there! Preparing your cart...'),
					duration: 5000,
				},
				<span key='slightly longer than expected'  >
					{T._('This is taking slightly longer than expected, but don’t worry-we’re on it!')}
					<br />
					{T._(' Please don’t close the page.')}
				</span>,
			];
		},
		[T, testingMode.enabled, designEditorMode.enabled],
	);
};

const GlobalLoader = () => {
	const isBootCompleted = useAppStore((state) => state.isBootCompleted);
	const { isAddingToCart } = useZakekeAddToCart();
	const { isCheckingForProfanity } = useProfanityFilter();
	const addToCartLoadingText = useAddToCartLoadingText();
	const globalLoader = useGlobalLoader((state) => ({
		isLoading: state.isLoading,
		currentConfig: state.currentConfig,
	}))
	if (!isBootCompleted) {
		return <Loading />;
	}
	if (isCheckingForProfanity) {
		return <Loading />;
	}
	if (isAddingToCart) {
		return <Loading darkBack forceDarkBackText text={addToCartLoadingText} />;
	}
	if (globalLoader.isLoading) {
		return <Loading darkBack forceDarkBackText text={globalLoader.currentConfig} />;
	}

	return null;
};

export default GlobalLoader;
