// Create the notification portal
const notificationsPortal = document.createElement('div');
notificationsPortal.id = 'notifications-portal';
document.body.appendChild(notificationsPortal);

const advancedSelectMenuPortal = document.createElement('div');
advancedSelectMenuPortal.id = 'advanced-select-menu-portal';
document.body.appendChild(advancedSelectMenuPortal)

// Create the dialog portal
const dialogsPortal = document.createElement('div');
dialogsPortal.id = 'dialogs-portal';
document.body.appendChild(dialogsPortal);

// Create the drop down portal
const dropdownPortal = document.createElement('div');
dropdownPortal.id = 'dropdown-portal';
document.body.appendChild(dropdownPortal);

// Create the photoEditor portal
const photoEditorPortal = document.createElement('div');
photoEditorPortal.id = 'photoEditor-portal';
document.body.appendChild(photoEditorPortal);

// Create the virtual try on portal
const vtoPortal = document.createElement('div');
vtoPortal.id = 'vto-portal';
document.body.appendChild(vtoPortal);

// Create the Tooltip portal
const tooltipPortal = document.createElement('div');
tooltipPortal.id = 'tooltip-container';
document.body.appendChild(tooltipPortal);

// Create the premium images portal
const premiumImagesPortal = document.createElement('div');
premiumImagesPortal.id = 'premiumImagesPortal';
document.body.appendChild(premiumImagesPortal);

// Full warning list
const fullWarningListPortal = document.createElement('div');
fullWarningListPortal.id = 'fullWarningListPortal';
document.body.appendChild(fullWarningListPortal);

export { notificationsPortal, advancedSelectMenuPortal, dialogsPortal, dropdownPortal, photoEditorPortal, tooltipPortal, vtoPortal, premiumImagesPortal, fullWarningListPortal };
