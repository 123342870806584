import React, { FC, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';

import useMainToolsMenu from '../../hooks/useMainToolsMenu';
import { ToolButton } from '../../hooks/useToolsButtonsList';
import { SMALLVERTICAL_BREAKPOINT_PX } from '../../shared/helpers';
import { useAppStore } from '../../state/store';
import Tooltip from '../widgets/tooltip';
import { Icon } from '../globals';

export const ToolsListItemButtonContainer = styled.button<{
	$highlight?: boolean;
}>`
	width: 100%;
	font-weight: 400;
	font-size: 11px;
	line-height: 1.2em;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	text-align: center;
	padding: 3px 5px;
	gap: 4px;
	color: ${(props) => props.theme.colors.iconSecondary};
	background: transparent;
	border: 0 none;
	min-height: 48px;

	${Icon} {
		width: 24px;
		height: 24px;
	}

	@media screen and (max-height: ${SMALLVERTICAL_BREAKPOINT_PX}) {
		min-height: 38px;

		padding-block: 2px;
		${Icon} {
			width: 18px;
			height: 18px;
		}
	}

	span {
		display: inline-block;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		max-width: 54px;
		text-align: end;
	}

	${(props) =>
		props.$highlight &&
		css`
			background: ${props.theme.colors.backgroundMain};
			color: ${props.theme.colors.primary};
		`};

	${(props) =>
		!props.disabled &&
		!props.$highlight &&
		css`
			cursor: pointer;

			@media (hover) {
				&:hover {
					color: ${props.theme.colors.iconSecondaryState};
				}
			}
		`}

	${(props) =>
		props.disabled &&
		css`
			background: transparent;
			color: ${props.theme.colors.textDisabled};
			${Icon} {
				color: ${props.theme.colors.iconDisabled};
			}
		`}
`;

type ToolsListItemButtonProps = {
	onClick?: React.MouseEventHandler<HTMLButtonElement>;
	highlight?: boolean;
	disabled?: boolean;
	expanded?: boolean;
	icon?: React.ReactNode;
	children?: React.ReactNode;
};

export const ToolsListItemButton: FC<ToolsListItemButtonProps> = ({
	children,
	icon,
	onClick,
	highlight = false,
	disabled = false,
}) => {
	return (
		<ToolsListItemButtonContainer onClick={onClick} $highlight={highlight} disabled={disabled}>
			{icon && <Icon>{icon}</Icon>}
			{children}
		</ToolsListItemButtonContainer>
	);
};

export const ToolsListContainer = styled.div<{ $expanded?: boolean }>`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 64px;
	gap: 8px;
`;

const ToolBarItem: FC<{ toolButton: ToolButton }> = ({ toolButton }) => {
	const [setMenuPage, activeMenuPage, setSelectedItemGuid, clearMenuHistory] = useAppStore((x) => [
		x.setMenuPage,
		x.menuPage,
		x.setSelectedItemGuid,
		x.clearMenuHistory,
	]);
	const subpages = toolButton.subPages || [];

	//check for the need of tooltip
	const textRef = useRef<HTMLSpanElement>(null);
	const hasTooltip = !!toolButton.tooltip;

	const content = (
		<ToolsListItemButton
			highlight={!![toolButton.menuPage, ...subpages].find((menuPage) => menuPage.page === activeMenuPage.page)}
			disabled={toolButton.disabled}
			onClick={() => {
				if (toolButton.menuPage.page === activeMenuPage.page) {
					return;
				}
				setSelectedItemGuid(null, {
					preventPageChange: true,
				});
				clearMenuHistory();
				setMenuPage(toolButton.menuPage);
			}}
			icon={toolButton.icon}
		>
			<span ref={textRef}>{toolButton.text}</span>
		</ToolsListItemButton>
	);

	return (
		<>
			{!hasTooltip && <>{content}</>}
			{hasTooltip && (
				<Tooltip html={toolButton.tooltip} position='left' addContainer>
					<>{content}</>
				</Tooltip>
			)}
		</>
	);
};

export type ToolsListProps = {
	opened?: boolean;
};
const ToolsList: FC<ToolsListProps> = () => {
	const [setMenuPage, activeMenuPage] = useAppStore((x) => [x.setMenuPage, x.menuPage]);
	const { sections } = useMainToolsMenu();

	useEffect(() => {
		// prevent none is selected if you switch from mobile to desktop
		if (activeMenuPage.page !== 'none') return;
		let designElementsPage: (typeof sections)[0]['buttons'][0]['menuPage'] | null = null;
		sections.find((s) => {
			const d = s.buttons.find((b) => b.key === 'design-elements');
			if (d) {
				designElementsPage = d.menuPage;
			}
			return d;
		});
		if (designElementsPage) {
			setMenuPage(designElementsPage);
		}
	}, [activeMenuPage.page, setMenuPage, sections]);

	return (
		<ToolsListContainer>
			{sections
				.flatMap(({ buttons }) => buttons)
				.map((toolButton, idx) => (
					<ToolBarItem key={idx} toolButton={toolButton} />
				))}
		</ToolsListContainer>
	);
};

export default ToolsList;
