import {
	UiLayout,
	UiLayoutCustomizerContainer,
	UiMenuAndToolbarContainer,
	UiMenuAndToolbarPagesContainer,
	UiMenuAndToolbarToolsActionsContainer,
	UiMenuAndToolbarToolsContainer,
} from './uiLayout';
import MenuSkeleton from '../../menu/menuSkeleton';
import ToolsListSkeleton from '../../menu/toolsListSkeleton';
import FooterSkeleton from '../footer/footerSkeleton';
import { useIsMobile } from '../../../hooks/useLayoutCheck';
import CustomizerSkeleton from '../customizer/customizerSkeleton';
import { styled } from 'styled-components';

const UiMenuAndToolbarPagesSkeleton = styled(UiMenuAndToolbarPagesContainer)`
	padding-block-start: 20px;
`;

export const UiMenuAndToolbarSkeleton = () => {
	return (
		<UiMenuAndToolbarContainer>
			<UiMenuAndToolbarToolsActionsContainer />
			<UiMenuAndToolbarPagesSkeleton>
				<MenuSkeleton />
			</UiMenuAndToolbarPagesSkeleton>
			<UiMenuAndToolbarToolsContainer>
				<ToolsListSkeleton />
			</UiMenuAndToolbarToolsContainer>
		</UiMenuAndToolbarContainer>
	);
};

const UiSkeleton = () => {
	const isMobile = useIsMobile();
	return (
		<UiLayout>
			<UiLayoutCustomizerContainer>
				<CustomizerSkeleton />
			</UiLayoutCustomizerContainer>
			{!isMobile && <UiMenuAndToolbarSkeleton />}
			<FooterSkeleton />
		</UiLayout>
	);
};

export default UiSkeleton;
