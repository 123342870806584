import { ProductVariant, useZakekeProduct, useZakekeTranslations } from '@zakeke/zakeke-customizer-react';
import styled from 'styled-components';

import useVariantChanger from '../../hooks/useVariantChanger';
import { isMobile } from '../../shared/helpers';
import { Dialog, DialogWindow, useDialogManager } from '../widgets/dialogs';
import EmptyMessage from '../widgets/emptyMessage';
import Gallery, { GalleryItem } from '../widgets/gallery';
import { FC, useState } from 'react';
import useVariantPrice from '../../hooks/useVariantPricing';

const WindowContainer = styled(DialogWindow)`
	min-height: 480px;
	max-height: calc(100vh - 50px);
`;

const WarningMessageContainer = styled.div`
	color: ${(props) => props.theme.colors.error};
	margin-bottom: 16px;
`;

const VariantSelectionDialog: FC<{
	title?: string;
	onVariantSelected?: () => void;
	mode: 'default' | 'shared-design';
}> = ({ title, onVariantSelected, mode }) => {
	const { currentDialogId, closeDialog } = useDialogManager();
	const product = useZakekeProduct();
	const { T } = useZakekeTranslations();
	const changeVariant = useVariantChanger();
	const { isVariantOutOfStock } = useVariantPrice();
	const [oosWarning, setOosWarning] = useState('');

	const handleSelectedClick = async (item: GalleryItem<ProductVariant>) => {
		if (mode === 'default') {
			await changeVariant(item.reference.id);
			closeDialog(currentDialogId);
		} else {
			const isVariantSelectedOutOfStock = await isVariantOutOfStock(item.reference.id, product.variants);
			if (!isVariantSelectedOutOfStock) {
				setOosWarning('');
				await changeVariant(item.reference.id);
				closeDialog(currentDialogId);
				onVariantSelected && onVariantSelected();
			} else
				setOosWarning(T._(`The variant ${item.reference.name} is out of stock. Please choose another variant`));
		}
	};

	if (!product || product.variants.length === 0) return <EmptyMessage message={T._('No variants available.')} />;

	return (
		<Dialog
			showCloseButton={false}
			windowDecorator={WindowContainer}
			title={title ?? T._('Please choose a variant')}
			buttons={[]}
		>
			{oosWarning && <WarningMessageContainer>{oosWarning}</WarningMessageContainer>}
			<Gallery
				items={product.variants.map((variant) => ({
					key: variant.id,
					previewUrl: variant.sides[0].thumbnailUrl,
					label: variant.name,
					reference: variant,
				}))}
				onItemSelected={(variantSelected) => {
					handleSelectedClick(variantSelected);
				}}
				galleryImageHeight={isMobile() ? 120 : 140}
				galleryImageWidth={isMobile() ? 120 : 140}
				galleryImageBlockPadding={5}
			/>
		</Dialog>
	);
};

export default VariantSelectionDialog;
