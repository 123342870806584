import styled, { css } from 'styled-components';
import { MOBILE_BREAKPOINT_PX, SMALLDESK_BREAKPOINT_PX } from '../../../shared/helpers';
import { darken, transparentize } from 'polished';

export const CustomizerMenuContainer = styled.div<{ $is3dPreviewAvailable?: boolean }>`
	user-select: none;
	pointer-events: none;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
	gap: 25px;
	padding: 18px 30px 20px;
	display: grid;
	grid-template-columns: 40px 1fr 40px 1fr 40px;
	grid-template-rows: 40px 1fr ${(props) => (props.$is3dPreviewAvailable ? '50px' : '')} 40px;
	grid-template-areas:
		'top top top top top'
		'center-left center center center center-right'
		'bottom-left bottom-left bottom-center bottom-right bottom-right';

	${(props) =>
		props.$is3dPreviewAvailable &&
		css`
			grid-template-areas:
				'top top top top top'
				'center-left center center center center-right'
				'3dpreviewspace 3dpreviewspace 3dpreviewspace 3dpreviewspace 3dpreviewspace'
				'bottom-left bottom-left bottom-center bottom-right bottom-right';
		`};

	@media screen and (max-width: ${SMALLDESK_BREAKPOINT_PX}) {
		padding-inline-start: 20px;
	}
`;

export const CustomizerMenuContainerMobile = styled.div<{ $is3dPreviewAvailable?: boolean }>`
	user-select: none;
	pointer-events: none;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 2;

	display: grid;
	gap: 12px 8px;
	padding-block: 15px 0;
	padding-inline: 0;
	grid-template-areas:
		'leftpad top top top top top rightpad'
		'leftpad mobile-main mobile-main mobile-main mobile-main mobile-main rightpad'
		'leftpad center-left center center center center-right rightpad'
		'leftpad tools-left tools-left tools-center tools-right tools-right rightpad'
		'bottom bottom bottom bottom bottom bottom bottom';

	grid-template-rows: auto 1fr auto minmax(54px, auto) auto;
	grid-template-columns: 4px minmax(54px, auto) minmax(54px, auto) 1fr minmax(54px, auto) minmax(54px, auto) 4px;
`;

type AreaStylePropsType = {
	$justifyContent?: 'flex-start' | 'center' | 'flex-end' | 'stretch';
	$alignItems?: 'flex-start' | 'center' | 'flex-end' | 'stretch';
	$flexDirection?: 'column' | 'row';
	$area:
		| 'top'
		| 'mobile-main'
		| 'center'
		| 'center-right'
		| 'center-left'
		| 'tools-left'
		| 'tools-center'
		| 'tools-right'
		| 'bottom';
};

export const CustomizerMenuAreaMobile = styled.div<AreaStylePropsType>`
	width: 100%;
	grid-area: ${(props) => props.$area};
	display: flex;
	justify-content: ${(props) => props.$justifyContent ?? 'flex-start'};
	align-items: ${(props) => props.$alignItems ?? 'center'};
	flex-direction: ${(props) => props.$flexDirection ?? 'row'};
	gap: 15px;
	position: relative;
	min-width: 0;
	min-height: 0;
	pointer-events: none;
	@media screen and (max-width: ${MOBILE_BREAKPOINT_PX}) {
		> * {
			pointer-events: auto;
		}
	}
	${(props) =>
		props.$area === 'top' &&
		css`
			z-index: 1;
		`}
`;

export const CustomizerMenuTop = styled.div`
	display: grid;
	width: 100%;
	grid-template-rows: 1fr;
	grid-template-columns: auto 1fr auto;
	grid-template-areas: 'left center right';
	gap: 25px;

	@media screen and (max-width: ${SMALLDESK_BREAKPOINT_PX}) {
		gap: 8px;
	}

	@media screen and (max-width: ${MOBILE_BREAKPOINT_PX}) {
		grid-template-rows: 1fr minmax(0, auto);
		grid-template-areas:
			'left center right'
			'bottom bottom bottom';
		gap: 8px;
		pointer-events: none;
	}
`;
export const CustomizerMenuBottom = styled(CustomizerMenuTop)``;

//calc inside template-rows to consider the gap space
export const CustomizerMenuSide = styled.div`
	display: grid;
	height: 100%;
	gap: 25px;
	grid-template-columns: 1fr;
	grid-template-rows: minmax(min-content, calc(60% - 5px)) minmax(min-content, calc(40% - 20px));
	grid-template-areas: 'side-top' 'side-bottom';
`;

export const CustomizerMenuAreaDesktop = styled.div<{
	$area: 'top' | 'bottom-right' | 'center-left' | 'center-right' | 'bottom-center';
}>`
	width: 100%;
	grid-area: ${(props) => props.$area};
	display: flex;
	position: relative;
	pointer-events: none;
	${(props) =>
		props.$area === 'bottom-center' &&
		css`
			@media screen and (max-width: ${SMALLDESK_BREAKPOINT_PX}) {
				left: 0;
			}
		`}
`;

type AreaGroupStyleProps = {
	$justifyContent?: 'flex-start' | 'center' | 'flex-end' | 'stretch';
	$alignItems?: 'flex-start' | 'center' | 'flex-end' | 'stretch';
	$flexDirection?: 'column' | 'row';
	$area: 'side-top' | 'left' | 'center' | 'right' | 'bottom' | 'bottom-right';
};

export const CustomizerMenuAreaGroup = styled.div<AreaGroupStyleProps>`
	width: 100%;
	grid-area: ${(props) => props.$area};
	display: flex;
	justify-content: ${(props) => props.$justifyContent ?? 'flex-start'};
	align-items: ${(props) => props.$alignItems ?? 'center'};
	flex-direction: ${(props) => props.$flexDirection ?? 'row'};
	gap: 12px;
	position: relative;
	min-width: 0;
	min-height: 0;
	pointer-events: none;
	${(props) =>
		props.$area === 'center' &&
		`
		z-index: 1;
	`};
	@media screen and (max-width: ${SMALLDESK_BREAKPOINT_PX}) {
		gap: 8px;
	}
`;

export const CustomizerMenuMobileProductInfo = styled.div<{ $hidden?: boolean }>`
	position: relative;
	width: 100%;
	padding: 12px 12px;
	z-index: 3;
	transition: opacity 0.2s ease 0.1s;
	${(props) => props.$hidden && 'opacity: 0; pointer-events:none; '}
	background-color: ${(props) => transparentize(0.1, darken(0.025, props.theme.colors.backgroundMain))};
	border: ${(props) => props.theme.panel};
	border-radius: ${(props) => props.theme.measures.borderRadius};
`;
