import { ErrorMessageDialog } from '../dialogs';
import { useZakekeTranslations } from '@zakeke/zakeke-customizer-react';
import React from 'react';
import styled from 'styled-components';

const MessageWrapper = styled.div`
	text-align: left;
	width: 100%;
	padding-block: 4px;
`;

const ErrorDetailsContainer = styled.div`
	text-align: left;
	width: 100%;
	min-height: 100px;
	padding-block: 4px;
	overflow-y: scroll;
	border: 1px solid ${(props) => props.theme.colors.borders};
	margin-top: 16px;
	padding: 8px;
`;

export type ErrorDialogProps = {
	title?: string;
	message: string;
	details?: string;
	showButtons?: boolean;
	onClose?: () => void;
};

const ErrorDialog: React.FC<ErrorDialogProps> = ({ title, message, details, showButtons = true, onClose }) => {
	const { T } = useZakekeTranslations();
	return (
		<ErrorMessageDialog
			title={title ?? T._('Error')}
			showCloseButton={false}
			message={
				<MessageWrapper>
					{message}
					{details && <ErrorDetailsContainer>{details}</ErrorDetailsContainer>}
				</MessageWrapper>
			}
			onClose={onClose}
			showButtons={showButtons}
		/>
	);
};

export default ErrorDialog;
