import { LoadingTextConfig } from '../components/widgets/loading/loadingText';
import { createWithEqualityFn } from 'zustand/traditional';
import { shallow } from 'zustand/shallow';

type GlobalLoaderController = {
	stack: [string, LoadingTextConfig | null][];
	currentConfig: LoadingTextConfig | null;
	isLoading: boolean;
	show: (id: string, text: LoadingTextConfig) => void
	hide: (id: string) => void
}
const deriveStateFromStack = (stack: [string, LoadingTextConfig | null][]) => {
	return ({
		stack,
		isLoading: stack.length > 0,
		currentConfig: stack.at(0)?.[1] ?? null,
	});
}
const useGlobalLoader = createWithEqualityFn<GlobalLoaderController>((set) => {
	return ({
		stack: [],
		currentConfig: null,
		isLoading: false,
		show: (id, text) => {
			set((prev) => deriveStateFromStack([...prev.stack, [id, text]]));
		},
		hide: (id: string) => {
			set((prev) => deriveStateFromStack(prev.stack.filter(([stackId]) => stackId !== id)));
		},
	});
}, shallow)

export default useGlobalLoader;
