import { useIsMobile } from '../../../hooks/useLayoutCheck';
import Skeleton from '../../widgets/skeleton';
import { CustomizerButton, CustomizerButtonGroup } from '../../globals';
import {
	CustomizerMenuAreaMobile,
	CustomizerMenuAreaDesktop,
	CustomizerMenuAreaGroup,
	CustomizerMenuContainer,
	CustomizerMenuContainerMobile,
	CustomizerMenuMobileProductInfo,
	CustomizerMenuSide,
	CustomizerMenuTop,
} from './customizerMenuLayout';

const CustomizerMenuMobileSkeleton = () => {
	return (
		<CustomizerMenuContainerMobile>
			<CustomizerMenuAreaMobile $area='top'>
				<CustomizerMenuTop>
					<CustomizerMenuAreaGroup $area='center'>
						<></>
					</CustomizerMenuAreaGroup>
					<CustomizerMenuAreaGroup $area='right'>
						<></>
					</CustomizerMenuAreaGroup>
					<CustomizerMenuAreaGroup $area='bottom' $justifyContent='center'>
						<></>
					</CustomizerMenuAreaGroup>
				</CustomizerMenuTop>
			</CustomizerMenuAreaMobile>
			<CustomizerMenuAreaMobile $area='center-left' $alignItems='stretch' $flexDirection='column'>
				<></>
			</CustomizerMenuAreaMobile>
			<CustomizerMenuAreaMobile $area='center-right' $flexDirection='column'>
				<></>
			</CustomizerMenuAreaMobile>
			<CustomizerMenuAreaMobile $area='tools-left' $alignItems='stretch' $flexDirection='row'>
				<Skeleton width='48px' height='48px' />
			</CustomizerMenuAreaMobile>
			<CustomizerMenuAreaMobile $area='tools-center' $justifyContent='center' $flexDirection='row'>
				<Skeleton variant='circle' width='48px' height='48px' />
			</CustomizerMenuAreaMobile>
			<CustomizerMenuAreaMobile
				$area='tools-right'
				$alignItems='stretch'
				$flexDirection='row'
				$justifyContent='flex-end'
			>
				<Skeleton width='48px' height='48px' />
			</CustomizerMenuAreaMobile>
			<CustomizerMenuAreaMobile $area='bottom'>
				<CustomizerMenuMobileProductInfo>
					<Skeleton variant='text' width='100%' fontSize='1em' />
					<br />
					<Skeleton variant='text' width='100%' fontSize='1em' />
				</CustomizerMenuMobileProductInfo>
			</CustomizerMenuAreaMobile>
		</CustomizerMenuContainerMobile>
	);
};
const CustomizerMenuDesktopSkeleton = () => {
	return (
		<CustomizerMenuContainer>
			<CustomizerMenuAreaDesktop $area='top'>
				<CustomizerMenuTop>
					<CustomizerMenuAreaGroup $area='left'></CustomizerMenuAreaGroup>
				</CustomizerMenuTop>
			</CustomizerMenuAreaDesktop>
			<CustomizerMenuAreaDesktop $area='center-left'>
				<CustomizerMenuSide>
					<CustomizerMenuAreaGroup $area='side-top' $alignItems='flex-end'>
						<CustomizerButtonGroup $vertical>
							<CustomizerButton $disabled>
								<Skeleton variant='rectangular' width='24px' height='24px' />
							</CustomizerButton>
							<CustomizerButton $disabled>
								<Skeleton variant='rectangular' width='24px' height='24px' />
							</CustomizerButton>
							<CustomizerButton $disabled>
								<Skeleton variant='rectangular' width='24px' height='24px' />
							</CustomizerButton>
						</CustomizerButtonGroup>
					</CustomizerMenuAreaGroup>
				</CustomizerMenuSide>
			</CustomizerMenuAreaDesktop>
			<CustomizerMenuAreaDesktop $area='bottom-center'>
				<CustomizerMenuAreaGroup $area='right' $alignItems='flex-end'>
					<CustomizerButtonGroup $vertical>
						<CustomizerButton $disabled>
							<></>
						</CustomizerButton>
					</CustomizerButtonGroup>
				</CustomizerMenuAreaGroup>
			</CustomizerMenuAreaDesktop>
			<CustomizerMenuAreaDesktop $area='center-right'>
				<CustomizerMenuSide>
					<CustomizerMenuAreaGroup $area='side-top' $alignItems='flex-end'>
						<CustomizerButtonGroup $vertical>
							<CustomizerButton $disabled>
								<Skeleton variant='rectangular' width='24px' height='24px' />
							</CustomizerButton>
							<CustomizerButton $disabled>
								<Skeleton variant='rectangular' width='24px' height='24px' />
							</CustomizerButton>
							<CustomizerButton $disabled>
								<Skeleton variant='rectangular' width='24px' height='24px' />
							</CustomizerButton>
						</CustomizerButtonGroup>
					</CustomizerMenuAreaGroup>
				</CustomizerMenuSide>
			</CustomizerMenuAreaDesktop>
			<CustomizerMenuAreaDesktop $area='bottom-right'>
				<></>
			</CustomizerMenuAreaDesktop>
		</CustomizerMenuContainer>
	);
};
const CustomizerMenuSkeleton = () => {
	const isMobile = useIsMobile();
	if (isMobile) {
		return <CustomizerMenuMobileSkeleton />;
	}
	return <CustomizerMenuDesktopSkeleton />;
};

export default CustomizerMenuSkeleton;
