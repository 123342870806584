import {
	Font,
	Image,
	ItemConstraints,
	ProductArea,
	ProductPrintType,
	ProductSide,
	TextArtType,
	ZakekeMatrix,
} from '@zakeke/zakeke-customizer-react';
import { PaletteColors } from './interfaces/paletteColors';
import { CustomizerElement, isTextElement, TextArtElement, TextElement } from '../components/interfaces';
import { getDefaultTextColor, getDefaultTextStrokeColor } from '../shared/helpers.text';
import { getFitNoAngleBoxBounds } from '../shared/helpers.box';

export interface selectedFontFacesI {
	normal: number;
	italic: number;
	bold: number;
	boldItalic: number;
}

const getSortedSideItems = (items: CustomizerElement[], sideId: number, removeGuid: string) => {
	return items
		.filter((x) => x.sideId === sideId && x.guid !== removeGuid)
		.sort((a, b) => a.index - b.index)
		.map((item, index) => ({ ...item, index }));
};

export const initialPosition = (
	area: ProductArea,
	printType: ProductPrintType,
	initialCoordinates?: { x: number; y: number; isCenter: boolean },
) => {
	let newPositionX = area.x + area.width / 2;
	let newPositionY = area.y + area.height / 2;
	if (initialCoordinates && !initialCoordinates.isCenter) {
		newPositionX = initialCoordinates.x;
		newPositionY = initialCoordinates.y;
	}
	return {
		realScaleX: 1,
		realScaleY: 1,
		realRotation: 0,
		width: area.width,
		height: area.height,
		positionX: newPositionX,
		positionY: newPositionY,
		realPositionX: newPositionX,
		realPositionY: newPositionY,
		replaceWidth: 20,
		replaceHeight: 20,
		rotation: 0,
		flipX: false,
		flipY: false,
		index: 0,
	};
};

export const initialImageSize = (side: ProductSide, printType: ProductPrintType, image: Image) => {
	const imageRatio = image.width / image.height;
	const areaWidth = side.areas[0].width * 0.9;
	const areaHeight = side.areas[0].height * 0.9;

	let width = areaWidth;
	let height = areaWidth / imageRatio;

	if (height > areaHeight) {
		height = areaHeight;
		width = areaHeight * imageRatio;
	}

	let result = {
		width,
		height,
		replaceWidth: width,
		replaceHeight: height,
	};

	if (printType.isClipartsImagesResizeEnabled && image.preferredWidth && image.preferredHeight) {
		result = {
			...result,
			width: (image.preferredWidth / 10) * side.ppcm,
			height: (image.preferredHeight / 10) * side.ppcm,
		};
	}

	return result;
};

// #region Item placement

/**
 * Move element below another item
 * @param Items The list of items
 * @param guid The guid of the item to move
 * @param belowGuid The guid of the item to move below
 * @returns The updated list of items
 */
export const moveItemToBelow = (items: CustomizerElement[], guid: string, belowGuid: string): CustomizerElement[] => {
	const item = items.find((i) => i.guid === guid);

	if (!item || items.length === 1) return items;

	let sideItems = getSortedSideItems(items, item.sideId, guid);

	const belowItem = sideItems.find((i) => i.guid === belowGuid);

	if (!belowItem) return items;

	const belowIndex = belowItem.index;

	sideItems = sideItems.map((x) => ({ ...x, index: x.index >= belowItem.index ? x.index + 1 : x.index }));

	const regularItems = items.filter(
		(item) => !item.constraints?.isAlwaysOnTop && !item.constraints?.isAlwaysOnBottom,
	);
	const alwaysOnTopItems = items.filter((item) => item.constraints?.isAlwaysOnTop);
	const alwaysOnBottomItems = items.filter((item) => item.constraints?.isAlwaysOnBottom);

	if (!item.constraints?.isAlwaysOnTop && !item.constraints?.isAlwaysOnBottom)
		if (!belowItem.constraints?.isAlwaysOnBottom) {
			sideItems.push({ ...item, index: belowIndex });
		}

	const newItems = regularItems.map((item) => {
		const sideItem = sideItems.find((x) => x.guid === item.guid);

		return { ...item, index: sideItem?.index ?? item.index };
	});

	return [...newItems, ...alwaysOnTopItems, ...alwaysOnBottomItems];
};

/**
 * Move an item above another item
 * @param items The list of all items
 * @param guid  The guid of the item to move
 * @param aboveGuid The guid of the item to move above
 * @returns The updated list of items
 */
export const moveItemToAbove = (items: CustomizerElement[], guid: string, aboveGuid: string): CustomizerElement[] => {
	const item = items.find((i) => i.guid === guid);

	if (!item || items.length === 1) return items;

	let sideItems = getSortedSideItems(items, item.sideId, guid);

	const aboveItem = sideItems.find((i) => i.guid === aboveGuid);

	if (!aboveItem) return items;

	const aboveIndex = aboveItem.index;

	sideItems = sideItems.map((x) => ({ ...x, index: x.index > aboveItem.index ? x.index + 1 : x.index }));

	const regularItems = items.filter((item) => !item.constraints?.isAlwaysOnTop);
	const alwaysOnTopItems = items.filter((item) => item.constraints?.isAlwaysOnTop);

	if (!item.constraints?.isAlwaysOnTop && !item.constraints?.isAlwaysOnBottom) {
		if (!aboveItem.constraints?.isAlwaysOnTop) sideItems.push({ ...item, index: aboveIndex + 1 });
	}

	const newItems = regularItems.map((item) => {
		const sideItem = sideItems.find((x) => x.guid === item.guid);

		return { ...item, index: sideItem?.index ?? item.index };
	});

	return [...newItems, ...alwaysOnTopItems];
};

/**
 * Move an item to the top of the list
 * @param items The list of all items
 * @param guid The guid of the item to move
 * @returns The updated list of items
 */
export const bringToFront = (items: CustomizerElement[], guid: string): CustomizerElement[] => {
	const item = items.find((i) => i.guid === guid);

	if (!item || items.length === 1) return items;

	const sideItems = getSortedSideItems(items, item.sideId, guid);

	return moveItemToAbove(items, guid, sideItems[sideItems.length - 1].guid);
};

/**
 * Move an item to the bottom of the list
 * @param items The list of all items
 * @param guid  The guid of the item to move
 * @returns The updated list of items
 */
export const sendToBack = (items: CustomizerElement[], guid: string): CustomizerElement[] => {
	const item = items.find((i) => i.guid === guid);

	if (!item || items.length === 1) return items;

	const sideItems = getSortedSideItems(items, item.sideId, guid);

	return moveItemToBelow(items, guid, sideItems[0].guid);
};

//  #region Customizer elements

/**
	ritorna true se non esiste oggetto contraints o se esiste nell'oggetto voce passata
*/
export const checkConstraints = (
	item: { constraints?: undefined | null | ItemConstraints },
	constraintsName: keyof ItemConstraints,
) => !item?.constraints || item.constraints[constraintsName];

export function getNewZIndex(items: CustomizerElement[], sideId: number) {
	const regularItems = items.filter((item) => !item.constraints?.isAlwaysOnTop);

	const sidesItems = regularItems.filter((x) => x.sideId === sideId);
	return sidesItems.length === 0 ? 0 : Math.max(...sidesItems.map((item) => item.index)) + 1;
}

export const replaceImage = (
	item: CustomizerElement,
	image: Image,
	side?: ProductSide,
	isClipartsResizeEnabled?: boolean,
	newItemMatrix?: ZakekeMatrix,
) => {
	let matrix = item.matrix;

	let { width: w1, height: h1 } = getFitNoAngleBoxBounds(
		{
			width: item.replaceWidth,
			height: item.replaceHeight,
		},
		image,
	);

	if (newItemMatrix) {
		matrix = newItemMatrix.clone();
	}

	if (isClipartsResizeEnabled === false && side) {
		let data = fixImageSize(side, isClipartsResizeEnabled, image.preferredWidth, image.preferredHeight);
		if (Object.hasOwn(data, 'width') && Object.hasOwn(data, 'height')) {
			w1 = data.width!;
			h1 = data.height!;
			matrix = void 0;
		}
	}

	return {
		imageId: image.imageID,
		initialColors: image.colors.map((x) => x.code),
		colors: image.colors.map((x) => x.code),
		width: w1,
		height: h1,
		replaceWidth: w1,
		replaceHeight: h1,
		preferredHeight: image.preferredHeight,
		preferredWidth: image.preferredWidth,
		matrix,
	};
};

export const fixImageSize = (
	side: ProductSide,
	isClipartsResizeEnabled: boolean,
	preferredWidth: number | undefined,
	preferredHeight: number | undefined,
) => {
	// Fixed image size

	if (!isClipartsResizeEnabled && preferredWidth && preferredHeight) {
		return {
			width: (preferredWidth / 10) * side.ppcm,
			height: (preferredHeight / 10) * side.ppcm,
			matrix: void 0,
		};
	}

	return {};
};

export const createDefaultText = (
	guid: string,
	side: ProductSide,
	printType: ProductPrintType,
	selectableColors: PaletteColors[] | null,
	usedColors?: Immutable.Map<number, string[]> | null,
	initialCoordinates?: { x: number; y: number; isCenter: boolean },
) => {
	const defaultTextColor = getDefaultTextColor(
		printType,
		selectableColors,
		side.id ? usedColors?.get(side.id) : null,
	);
	const defaultTextStrokeColor = getDefaultTextStrokeColor(
		printType,
		selectableColors,
		defaultTextColor,
		side.id ? usedColors?.get(side.id) : null,
	);

	const fontSizeSetted = handleFontSize({
		min: printType.minTextFontSize,
		max: printType.maxTextFontSize,
		defaultSize: printType.defaultTextFontSize,
		list: printType.fontSizeList,
		side,
	});

	const maxArea = side.areas.reduce((prev, current) => (prev.width > current.width ? prev : current));

	return {
		type: 'text',
		name: '',
		content: '',
		tag: '',
		guid,
		syncGuid: '',
		sideId: side.id,
		fontFamily: printType.defaultFont?.name ?? printType.fonts[0].name,
		fontSize: fontSizeSetted,
		bold: false,
		italic: false,
		justification: 'center',
		verticalAlignment: 'top',
		color: defaultTextColor,
		isTextBox: false,
		isCurvedText: false,
		shadowColor: null,
		shadowAngle: printType.defaultShadowAngle ?? 45,
		shadowBlur: printType.defaultShadowBlur ?? 2,
		shadowDistance: printType.defaultShadowDistance ?? 2,
		collageBoxId: null,
		strokeWidth: 0,
		strokeColor: defaultTextStrokeColor,
		...initialPosition(maxArea, printType, initialCoordinates),
	} as TextElement;
};

export const createDefaultTextArt = (
	guid: string,
	sideId: number,
	textArtType: TextArtType,
	fonts: Font[],
	printType: ProductPrintType,
	selectableColors: PaletteColors[] | null,
	usedColors: Immutable.Map<number, string[]> | null,
	initialCoordinates?: { x: number; y: number; isCenter: boolean },
) => {
	const defaultTextColor = getDefaultTextColor(printType, selectableColors, sideId ? usedColors?.get(sideId) : null);
	const defaultTextStrokeColor = getDefaultTextStrokeColor(
		printType,
		selectableColors,
		defaultTextColor,
		sideId ? usedColors?.get(sideId) : null,
	);
	let newMatrix = new ZakekeMatrix();
	if (initialCoordinates && !initialCoordinates.isCenter) {
		newMatrix = new ZakekeMatrix().translate(-initialCoordinates.x, -initialCoordinates.y);
	}

	return {
		guid,
		sideId: sideId,
		type: 'text-art',
		content: '',
		fontFamilyId: printType.defaultFont?.id ?? fonts[0].id,
		fontFaceId: printType.defaultFont?.faces[0].id ?? fonts[0].faces[0].id,
		strokeColor: defaultTextStrokeColor,
		strokeWidth: 0,
		fillColor: defaultTextColor,
		name: '',
		positionX: 0,
		positionY: 0,
		rotation: 0,
		matrix: newMatrix,
		width: 0,
		height: 0,
		replaceHeight: 0,
		replaceWidth: 0,
		index: 0,
		collageBoxId: null,

		typeId: textArtType.id,
		configuration: {
			angleValue: textArtType.config.angleValue,
			curveValue: textArtType.config.curveValue,
			intensB: textArtType.config.intensB,
			intensT: textArtType.config.intensT,
			isBottom: textArtType.config.isBottom,
			isBridge: textArtType.config.isBridge,
			isMiddle: textArtType.config.isMiddle,
			isTop: textArtType.config.isTop,
			isTriangle: textArtType.config.isTriangle,
		},
	} as TextArtElement;
};

export const canItemBeSelected = (item: CustomizerElement) => {
	// canEdit on text elements just refers to the text content
	if (!isTextElement(item)) {
		return item?.constraints?.canEdit !== false;
	} else {
		return (
			item?.constraints?.canEdit !== false ||
			item?.constraints?.canResize !== false ||
			item?.constraints?.canRotate !== false ||
			item?.constraints?.canDelete !== false ||
			item?.constraints?.canChangeFontColor !== false ||
			item?.constraints?.canChangeFontFamily !== false ||
			item?.constraints?.canChangeFontWeight !== false ||
			item?.constraints?.canChangeJustification !== false ||
			item?.constraints?.canChangeLetterSpacing !== false ||
			item?.constraints?.canChangeLineSpacing !== false ||
			item?.constraints?.canChangeShadowAngle !== false ||
			item?.constraints?.canChangeShadowBlur !== false ||
			item?.constraints?.canChangeShadowColor !== false ||
			item?.constraints?.canChangeShadowDistance !== false ||
			item?.constraints?.canChangeTextPathMode !== false ||
			item?.constraints?.canChangeTextBoxMode !== false ||
			item?.constraints?.canChangeTextStrokeColor !== false ||
			item?.constraints?.canChangeTextStrokeWidth !== false
		);
	}
};

//  #region Fonts

const handleFontSize = ({
	min = 0,
	max = 0,
	defaultSize = 0,
	list = [],
	side,
}: {
	min: number | null;
	max: number | null;
	defaultSize: number | null;
	list: number[] | null;
	side: ProductSide;
}) => {
	const sideWidth = side.areas[0].width;
	const sideHeight = side.areas[0].height;
	// all this is to calculate the default font size in a similar way to old editor
	const widthCorrection = sideWidth < 75 ? 0.5 : sideWidth < 150 ? 0.25 : 0.15;
	const heightCorrection = sideHeight < 75 ? 0.5 : sideHeight < 150 ? 0.25 : 0.15;
	const widthHeightReference = Math.min(sideWidth * widthCorrection, sideHeight * heightCorrection);
	const factorCorrection = widthHeightReference > 75 ? 10 : 1;
	let defaultFontSize =
		Math.round(((widthHeightReference / side.ppcm / 2.54) * 72) / factorCorrection) * factorCorrection;

	// for min e max
	if (!defaultSize && max && min) {
		return Math.floor((min + max) / 2);
	}

	// for fontSize list
	if (!defaultSize && list && list.length > 0) {
		return list[0];
	}

	// default
	if (defaultSize) {
		return defaultSize;
	}

	return defaultFontSize;
};

export const setOnFontFaceChange =
	(selectedFontFaces: selectedFontFacesI, isItalic: boolean, isBold: boolean) =>
	(face: 'Bold' | 'Italic'): number => {
		switch (face) {
			case 'Bold':
				if (isBold) {
					if (isItalic) return selectedFontFaces.italic;
					else return selectedFontFaces.normal;
				}
				if (isItalic && selectedFontFaces.boldItalic !== 0) return selectedFontFaces.boldItalic;
				else if (!isItalic) return selectedFontFaces.bold;
				break;
			case 'Italic':
				if (isItalic) {
					if (isBold) return selectedFontFaces.bold;
					else return selectedFontFaces.normal;
				}
				if (isBold && selectedFontFaces.boldItalic !== 0) return selectedFontFaces.boldItalic;
				else if (!isBold) return selectedFontFaces.italic;
				break;
		}
		return selectedFontFaces.normal;
	};

export function availableFontWeightsStyles(selectedFont: Font | undefined) {
	const selectedFontFaces: selectedFontFacesI = {
		normal: 0,
		italic: 0,
		bold: 0,
		boldItalic: 0,
	};
	if (selectedFont?.faces && selectedFont.faces.length > 0) {
		selectedFont.faces.forEach((x) => {
			if (selectedFontFaces.normal === 0 && x.style === 'Normal' && ['Normal', 'Medium'].includes(x.weight)) {
				selectedFontFaces.normal = x.id;
				return;
			}
			if (selectedFontFaces.italic === 0 && x.style === 'Italic' && ['Normal', 'Medium'].includes(x.weight)) {
				selectedFontFaces.italic = x.id;
				return;
			}
			if (selectedFontFaces.bold === 0 && x.style === 'Normal' && x.weight === 'Bold') {
				selectedFontFaces.bold = x.id;
				return;
			}
			if (selectedFontFaces.boldItalic === 0 && x.style === 'Italic' && x.weight === 'Bold') {
				selectedFontFaces.boldItalic = x.id;
				return;
			}
		});
	}
	return selectedFontFaces;
}

export function retrieveAvailableFontStyles(
	font: Font | undefined,
	text: TextElement | undefined,
	isForUpdate: boolean = false,
) {
	let canChangeItalic = false;
	let canChangeBold = false;
	let multipleFontFaces = false;

	if (!font || !text)
		return {
			canChangeItalic,
			canChangeBold,
		};

	multipleFontFaces = font.faces.length > 1;

	const fontFacesAvailable = availableFontWeightsStyles(font);

	let isItalic = isForUpdate
		? !multipleFontFaces && (fontFacesAvailable.italic || fontFacesAvailable.boldItalic)
		: (!multipleFontFaces && (fontFacesAvailable.italic || fontFacesAvailable.boldItalic)) || text.italic;
	let isBold = isForUpdate
		? !multipleFontFaces && (fontFacesAvailable.bold || fontFacesAvailable.boldItalic)
		: (!multipleFontFaces && (fontFacesAvailable.bold || fontFacesAvailable.boldItalic)) || text.bold;

	if (isItalic) {
		canChangeItalic =
			(isBold ? fontFacesAvailable.bold || fontFacesAvailable.normal : fontFacesAvailable.normal) !== 0;
		canChangeBold = (isBold ? fontFacesAvailable.italic : fontFacesAvailable.boldItalic) !== 0;
	} else {
		canChangeItalic = (isBold ? fontFacesAvailable.boldItalic : fontFacesAvailable.italic) !== 0;
		canChangeBold = (isBold ? fontFacesAvailable.normal : fontFacesAvailable.bold) !== 0;
	}

	return {
		canChangeItalic,
		canChangeBold,
	};
}

export const getItemAreaWidth = (item: CustomizerElement, side: ProductSide): number => {
	const { positionX, positionY } = item;
	let selectedArea: ProductArea | null = null;
	let minDistance = Infinity;

	for (const area of side.areas) {
		const { x, y, width, height } = area;

		// if the item is inside the selected area, return it
		if (positionX >= x && positionX <= x + width && positionY >= y && positionY <= y + height) {
			return width;
		}

		const areaCenterX = x + width / 2;
		const areaCenterY = y + height / 2;
		const distance = Math.sqrt((positionX - areaCenterX) ** 2 + (positionY - areaCenterY) ** 2);

		if (distance < minDistance) {
			minDistance = distance;
			selectedArea = area;
		}

		// we are quite close to the area, no need to continue
		if (minDistance < 5) break;
	}

	if (!selectedArea) selectedArea = side.areas[0];

	return selectedArea.width;
};
