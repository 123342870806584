import { useZakekeTranslations } from '@zakeke/zakeke-customizer-react';
import { ToolNames } from '../shared/tools';
import { useMemo } from 'react';
import { useTheme } from 'styled-components';

const useToolsDefaultNames = (): ToolNames => {
	const { T } = useZakekeTranslations();

	return useMemo(
		() => ({
			designElements: {
				text: T._('Design'),
				longText: T._('Design'),
			},
			uploadImage: {
				text: T._('Uploads'),
				longText: T._('Uploads'),
			},
			cliparts: {
				text: T._('Images'),
				longText: T._('Images gallery'),
			},
			text: {
				text: T._('Text'),
				longText: T._('Text'),
			},
			textArt: {
				text: T._('TextArt'),
				longText: T._('TextArt'),
			},
			background: {
				text: T._('Fill'),
				longText: T._('Fill Background'),
			},
			templates: {
				text: T._('Templates'),
				longText: T._('Pre-Designed Templates'),
			},
			collages: {
				text: T._('Collages'),
				longText: T._('Collages'),
			},
			shapes: {
				text: T._('Shapes'),
				longText: T._('Shapes'),
			},
			variants: {
				text: T._('Variants'),
				longText: T._('Switch Variants'),
			},
			printingMethods: {
				text: T._('Printing methods'),
				longText: T._('Printing methods'),
			},
			drafts: {
				text: T._('My saved designs'),
				longText: T._('My saved designs'),
			},
			cart: {
				text: T._('Add to cart'),
				longText: T._('Add to cart'),
			},
		}),
		[T],
	);
};

const useToolsNames = () => {
	const defaultNames = useToolsDefaultNames();
	const theme = useTheme();

	return useMemo(() => {
		const result: ToolNames = {} as ToolNames;

		for (const key in defaultNames) {
			const keyName = key as keyof typeof defaultNames;
			const tool = theme?.tools[keyName] ?? {};

			result[keyName] = {
				// also for empty string must be used default text
				text: tool?.text || defaultNames[keyName].text,
				// also for empty string must be used default long text
				longText: tool?.longText || defaultNames[keyName].longText,
			};
		}

		return result;
	}, [defaultNames, theme.tools]);
};

export default useToolsNames;
