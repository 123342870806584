import { InterfaceSettings } from '@zakeke/zakeke-customizer-react';
import { DefaultTheme } from 'styled-components';
import { createTheme } from './theme';

export type InterfaceSettingsTheme = Partial<{
	fontFamily: DefaultTheme['fontFamily'];
	colors: DefaultTheme['colors'];
	tools: DefaultTheme['tools'];
	desktopLayout?: Partial<DefaultTheme['desktopLayout']>;
}>;
const createThemeFromZakekeInterfaceSettings = (settings: InterfaceSettings<InterfaceSettingsTheme>): DefaultTheme => {
	return createTheme({
		fontFamily: settings.theme.fontFamily,
		colors: settings.theme.colors,
		tools: settings.theme.tools,
		pricing: {
			showPrice: settings.general.showPrice,
			priceInfo: settings.general.priceText,
		},
		zoom: {
			zoomPercentageIncrement: settings.general.zoomPercentageIncrement,
		},
		preview3d: {
			allowPreview3DExpandOnContainerClick: settings.general.allowPreview3DExpandOnContainerClick,
			showResize3DPreview: settings.general.showResize3DPreview,
			showResize3DHeight: settings.general.height3DPreview,
			showResize3DWidth: settings.general.width3DPreview,
		},
		general: {
			measurementUnit: settings.general.unitOfMeasurement,
			showElementsDimensions: settings.general.showElementsDimensions,
			showSideDimensions: settings.general.showSideDimensions,
			showAreaDimensions: settings.general.showAreaDimensions,
			showSelectedElementDimensions: settings.general.showSelectedElementDimensions,
			isUndoRedoEnabled: settings.general.canUndoRedo,
		},
		desktopLayout: {
			menuPosition: settings.theme.desktopLayout?.menuPosition,
			footerPosition: settings.theme.desktopLayout?.footerPosition,
		}
	});
};

export default createThemeFromZakekeInterfaceSettings;
