import { useZakekeProduct } from '@zakeke/zakeke-customizer-react';
import { useCallback } from 'react';
import { useAppStore } from '../state/store';

const useGetSelectedVariant = () => {
	const product = useZakekeProduct();
	return useCallback(() => {
		const { selectedVariantId } = useAppStore.getState();
		return product?.variants.find(x => x.id === selectedVariantId);
	}, [product]);
}

export default useGetSelectedVariant;
