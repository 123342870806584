import { useCallback } from 'react';
import { includes, sample, some } from 'lodash';
import {
	CustomizerElement,
	ImageElement,
	isImageElement,
	isShapeElement,
	isTextArtElement,
	isTextElement,
	ShapeElement,
	TextArtElement,
	TextElement,
} from '../components/interfaces';
import { PaletteColors } from '../state/interfaces/paletteColors';
import { SideAreaBackgrounds, useAppStore, useGetItems, useSetItems } from '../state/store';

import {
	ColorMapping,
	Image,
	Product,
	ProductVariant,
	useZakekeHelpers,
	useZakekeProduct,
	useZakekeUsedColors,
	useZakekeValidateSideForPrintType,
	useZakekeTranslations,
} from '@zakeke/zakeke-customizer-react';
import { checkTextStrokeRestrictions } from '../shared/helpers.text';
import useSelectedSide from '../hooks/useSelectedSide';
import { Map } from 'immutable';
import useSelectedVariant from './useSelectedVariant';
import { useUndoRedoCommit } from './useUndoRedo';
import ErrorDialog from '../components/widgets/errorDialog';
import { useDialogManager } from '../components/widgets/dialogs';
import { isMobile } from '../shared/helpers';

class UserPaletteConfirmError extends Error {
	constructor(message: string, options?: any) {
		super(message, options);
		this.name = 'UserPaletteConfirmError';
	}
}

const prepareMethods = (
	selectedVariant: ProductVariant | undefined,
	actualPrintMethods: Map<number, number>,
	sideId: number,
	printTypeId: number,
	allSides = false,
) => {
	let newPrintMethods;
	if (allSides) {
		const mapKeys = actualPrintMethods.keys();
		let tempPrintMethods = actualPrintMethods;
		let tempKeyVal = mapKeys.next();
		const arrayKV: number[] = [];
		while (!tempKeyVal.done) {
			arrayKV.push(tempKeyVal.value);
			tempKeyVal = mapKeys.next();
		}
		for (let i = 0; i < arrayKV.length; i++) {
			if (
				selectedVariant &&
				selectedVariant.sides?.find((x) => x.id === arrayKV[i])?.printTypes.find((x) => x.id === printTypeId)
			) {
				tempPrintMethods = tempPrintMethods.set(arrayKV[i], printTypeId);
			}
		}
		newPrintMethods = tempPrintMethods;
	} else {
		newPrintMethods = actualPrintMethods.set(sideId, printTypeId);
	}
	return newPrintMethods;
};

export const sliceColorsWithRandomPadding = (colors: string[], sliceLength: number) => {
	const temp = colors.slice();
	while (temp.length < sliceLength) {
		temp.push(getRandomColor());
	}
	return temp.slice(0, sliceLength);
};

function getRandomColor() {
	const letters = '0123456789ABCDEF';
	let color = '#';
	for (let i = 0; i < 6; i++) {
		color += sample(letters);
	}
	return color;
}

export const useGetPrintMethodForSide = () => {
	const sidesPrintMethods = useAppStore((store) => store.sidesPrintMethods);
	const product = useZakekeProduct();

	return useCallback(
		(sideId: number) => {
			return product?.printTypes.find((x) => x.id === sidesPrintMethods.get(sideId)) ?? product?.printTypes[0];
		},
		[product?.printTypes, sidesPrintMethods],
	);
};

export const usePrintMethodForSide = (sideId: number) => {
	const product = useZakekeProduct();
	return useAppStore(
		(store) =>
			product?.printTypes.find((x) => x.id === store.sidesPrintMethods.get(sideId)) ?? product?.printTypes[0],
	);
};

export const useAdaptImageItemToPrintMethods = () => {
	const setMenuPage = useAppStore((x) => x.setMenuPage);
	const product = useZakekeProduct();
	const usedColors = useZakekeUsedColors();
	const { getSelectableColorsForImage, getCurrentImageDefaultColorMappings, getImage } = useZakekeHelpers();
	/**
	 * Adapt an element to a print type by applying the print type rules
	 * @param item The item to adapt
	 * @param printTypeId  The print type id
	 * @param sideId The side id
	 * @param useCurrentColors If true, the current colors will used when deciding the new colors.
	 * It should be true when adding new items, and false when loading new designs o changing print types
	 * @param askUserForRecoloring If true, the user will be asked for recoloring the image
	 * @returns
	 */
	return useCallback(
		async (
			item: ImageElement,
			printTypeId: number,
			sideId: number,
			askUserForRecoloring?: boolean,
			availableColors?: string[],
		) => {
			const printType = product?.printTypes.find((x) => x.id === printTypeId);

			if (!printType) throw new Error(`Cannot find print type ${printTypeId}`);
			const { colors: imageColors, isLegacyImageColor } = getSelectableColorsForImage(item.sideId, null, printTypeId, false, !item.isVector);
			const sideUsedColors = sideId ? usedColors.get(sideId) : null;
			const maxColors = printType.maxColors;
			const paletteColors = printType.paletteColors;

			// no max colors and no image colors => reset color map
			if (!maxColors && !imageColors?.length) {
				delete item.colorMappings;
				return item;
			}

			const getUsedColorsAvailableForImage = () => {
				if (sideUsedColors) {
					return sideUsedColors.filter((color) =>
						imageColors?.some((x) => x.hex.toUpperCase() === color.toUpperCase()),
					);
				}
				return [];
			};

			// palette for recoloring the image
			const getColorPaletteToUseForAutoRecoloring = (image: Image) => {
				if (maxColors) {
					if (imageColors && imageColors.length > 0) {
						const usedColorUsableForImage = getUsedColorsAvailableForImage();
						if (usedColorUsableForImage.length >= maxColors) {
							return usedColorUsableForImage;
						}
						return [...usedColorUsableForImage, ...imageColors.map((color) => color.hex)].slice(
							0,
							maxColors,
						);
					}
					if (sideUsedColors && sideUsedColors.length > 0) {
						return availableColors
							? sliceColorsWithRandomPadding([...availableColors], maxColors)
							: sliceColorsWithRandomPadding(
									[...sideUsedColors, ...image.colors.map((color) => color.code)],
									maxColors,
							  );
					}
					return [];
				}
				if (imageColors && imageColors.length > 0) {
					return imageColors.map((color) => color.hex);
				}
				if (sideUsedColors && !!maxColors) {
					return sideUsedColors;
				}
				return [];
			};

			const getColorMappings = async (): Promise<ColorMapping[]> => {
				const image = await getImage(item.imageId);
				if (image.type === 'Vector' || image.format.toLowerCase() === 'svg') {
					if (image.containsRaster || image.isMulticolor || image.colors.length > 100) {
						throw new Error('Image contains more than 100 colors, rasters or gradients.');
					}
				}
				const colorMappings = await getCurrentImageDefaultColorMappings(
					item.imageId,
					printTypeId,
					getColorPaletteToUseForAutoRecoloring(image),
				);
				if (!askUserForRecoloring) {
					return colorMappings;
				}
				if (
					// colori identici
					colorMappings.every((map) => map.src.toLowerCase() === map.dest?.toLowerCase()) ||
					// solo un colore disponibile
					(maxColors === 1 && (sideUsedColors?.length ?? 0) > 0) ||
					(maxColors === 1 && paletteColors.length > 0)
				) {
					return colorMappings;
				}
				return new Promise((resolve, reject) => {
					const onReject = (avoidRedirect?: boolean) => {
						reject(new UserPaletteConfirmError('Adapt Image Closed'));
						if (avoidRedirect) return;
						isMobile() ? setMenuPage({ page: 'none' }) : setMenuPage({ page: 'design-elements' });
					};
					setMenuPage(
						{
							page: 'recolor-image',
							image,
							sideId: item.sideId,
							printTypeId: printTypeId,
							initialColorMappings: colorMappings,
							onContinue: (colorMappings: ColorMapping[]) => {
								resolve(colorMappings);
							},
							onClose: onReject,
						},
						false,
						() => onReject(true),
					);
				});
			};

			if (imageColors && imageColors.length > 0 && isLegacyImageColor) {
				const colorMappings = await getColorMappings();
				const colors = colorMappings.map((x) => x.dest);
				if (item.maskShapeId && item.maskShapeStrokeColor) {
					return {
						...item,
						colors,
						maskShapeStrokeColor: colors[0]
					}
				}
				return {
					...item,
					colors,
				};
			}
			if ((imageColors && imageColors.length > 0) || !!maxColors) {
				const colorMappings = await getColorMappings();

				if (item.maskShapeId && item.maskShapeStrokeColor) {
					return {
						...item,
						colorMappings,
						maskShapeStrokeColor: colorMappings[0].dest,
					};
				}

				return {
					...item,
					colorMappings,
				};
			}

			return item;
		},
		[
			getCurrentImageDefaultColorMappings,
			getImage,
			getSelectableColorsForImage,
			product?.printTypes,
			setMenuPage,
			usedColors,
		],
	);
};

export const useAdaptTextItemToPrintMethods = (isTextUppercase?: boolean) => {
	const product = useZakekeProduct();
	const usedColors = useZakekeUsedColors();
	const { getSelectableColorsForText, getNearestColorInSelectableColors } = useZakekeHelpers();

	/**
	 * Adapt an element to a print type by applying the print type rules
	 * @param item The item to adapt
	 * @param printTypeId  The print type id
	 * It should be true when adding new items, and false when loading new designs o changing print types
	 * @returns
	 */
	return useCallback(
		async (item: TextElement, printTypeId: number, sideId: number) => {
			const printType = product?.printTypes.find((x) => x.id === printTypeId);

			if (!printType) throw new Error(`Cannot find print type ${printTypeId}`);

			const getNearestPrintTypeTextColor = (
				defaultTextColor: string,
				selectableColors: PaletteColors[] | null,
				sideUsedColors?: string[] | null,
			) => {
				if (
					sideUsedColors &&
					printType &&
					printType.maxColors &&
					printType.maxColors - 1 <= sideUsedColors?.length
				) {
					defaultTextColor =
						sideUsedColors.slice(0, printType.maxColors).find((c) => c === defaultTextColor) ??
						sideUsedColors[0];
				}
				if (
					printType.textColors &&
					printType.textColors.length > 0 &&
					!printType.textColors.includes(defaultTextColor)
				) {
					defaultTextColor =
						getNearestColorInSelectableColors(sideId, defaultTextColor, printType.id, false) ??
						printType.textColors[0];
				}
				if (
					selectableColors &&
					selectableColors.length > 0 &&
					!selectableColors.find((x) => x.hex === defaultTextColor)
				) {
					defaultTextColor =
						getNearestColorInSelectableColors(sideId, defaultTextColor, printType.id, false) ??
						selectableColors?.[0].hex ??
						null;
				}
				return defaultTextColor;
			};

			const sideUsedColors = sideId ? usedColors.get(sideId) : null;
			const textColors = getSelectableColorsForText(item.sideId, null, printTypeId, false);
			const newItem: TextElement = { ...item };

			// Text case
			if (isTextUppercase) {
				newItem.content = newItem.content.toUpperCase();
			}

			// Line spacing
			((newItem) => {
				if (
					printType.minTextLineSpacing != null &&
					newItem.lineSpacing !== undefined &&
					newItem.lineSpacing < printType.minTextLineSpacing
				) {
					newItem.lineSpacing = printType.minTextLineSpacing;
				}

				if (
					printType.maxTextLineSpacing != null &&
					newItem.lineSpacing !== undefined &&
					newItem.lineSpacing > printType.maxTextLineSpacing
				) {
					newItem.lineSpacing = printType.maxTextLineSpacing;
				}
			})(newItem);

			// Letter spacing
			((newItem) => {
				if (
					printType.minTextLetterSpacing != null &&
					newItem.letterSpacing !== undefined &&
					newItem.letterSpacing < printType.minTextLetterSpacing
				) {
					newItem.letterSpacing = printType.minTextLetterSpacing;
				}

				if (
					printType.maxTextLetterSpacing != null &&
					newItem.letterSpacing !== undefined &&
					newItem.letterSpacing > printType.maxTextLetterSpacing
				) {
					newItem.letterSpacing = printType.maxTextLetterSpacing;
				}
			})(newItem);

			// Font family
			((newItem) => {
				if (!printType.fonts.find((x) => x.name === newItem.fontFamily)) {
					newItem.fontFamily = printType.defaultFont?.name ?? printType.fonts[0].name;
				}
			})(newItem);

			// Text colors
			((newItem) => {
				newItem.color = getNearestPrintTypeTextColor(newItem.color, textColors, sideUsedColors);
				if (newItem.shadowColor) {
					newItem.shadowColor = getNearestPrintTypeTextColor(newItem.shadowColor, textColors, sideUsedColors);
				}
			})(newItem);

			// Text stroke
			((newItem) => {
				const { canChangeTextStrokeWidth, minTextStrokeWidth, maxTextStrokeWidth } =
					checkTextStrokeRestrictions(newItem, printType);

				// stroke disabled
				if (!canChangeTextStrokeWidth) {
					newItem.strokeWidth = 0;
				} else {
					// stroke enabled:
					if (minTextStrokeWidth && newItem.strokeWidth < minTextStrokeWidth) {
						newItem.strokeWidth = minTextStrokeWidth;
					}
					if (maxTextStrokeWidth && newItem.strokeWidth > maxTextStrokeWidth) {
						newItem.strokeWidth = maxTextStrokeWidth;
					}
				}
				// stroke color:
				newItem.strokeColor = getNearestPrintTypeTextColor(newItem.strokeColor, textColors, sideUsedColors);
			})(newItem);

			return newItem;
		},
		[
			getSelectableColorsForText,
			isTextUppercase,
			product?.printTypes,
			usedColors,
			getNearestColorInSelectableColors,
		],
	);
};

export const useAdaptShapeItemToPrintMethods = () => {
	const setMenuPage = useAppStore((x) => x.setMenuPage);
	const product = useZakekeProduct();
	const usedColors = useZakekeUsedColors();
	const {
		getImage,
		getSelectableColorsForImage,
		getCurrentImageDefaultColorMappings,
		getNearestColorInSelectableColors,
	} = useZakekeHelpers();

	/**
	 * Adapt an element to a print type by applying the print type rules
	 * @param item The item to adapt
	 * @param printTypeId  The print type id
	 * @param sideId The side id
	 * @param useCurrentColors If true, the current colors will used when deciding the new colors.
	 * It should be true when adding new items, and false when loading new designs o changing print types
	 * @param askUserForRecoloring If true, the user will be asked for recoloring the image
	 * @returns
	 */
	return useCallback(
		async (
			item: ShapeElement,
			printTypeId: number,
			sideId: number,
			useCurrentColors: boolean,
			askUserForRecoloring?: boolean,
		) => {
			const printType = product?.printTypes.find((x) => x.id === printTypeId);

			if (!printType) throw new Error(`Cannot find print type ${printTypeId}`);

			const adaptFilledImage = async (item: ShapeElement) => {
				if (item.filledImageId) {
					const { colors: filledImageColors } = getSelectableColorsForImage(item.sideId, null, printTypeId, false);
					const sideUsedColors = sideId ? usedColors.get(sideId) : null;
					const maxColors = printType.maxColors;

					// image not need recoloring
					if (!maxColors && !filledImageColors?.length) {
						item.filledImageColorMapping = item.filledImageInitialColors.map((i) => ({ src: i, dest: i }));
						item.filledImageColors = [...item.filledImageInitialColors];
						return item;
					}

					const getUsedColorsAvailableForImage = () => {
						if (sideUsedColors) {
							return sideUsedColors.filter((color) =>
								filledImageColors?.some((x) => x.hex.toUpperCase() === color.toUpperCase()),
							);
						}
						return [];
					};
					const getColorPaletteToUseForAutoRecoloring = (image: Image) => {
						if (maxColors) {
							if (filledImageColors && filledImageColors.length > 0) {
								const usedColorUsableForImage = getUsedColorsAvailableForImage();
								if (usedColorUsableForImage.length >= maxColors) {
									return usedColorUsableForImage;
								}
								return [
									...usedColorUsableForImage,
									...filledImageColors.map((color) => color.hex),
								].slice(0, maxColors);
							}
							if (sideUsedColors && sideUsedColors.length > 0) {
								return sliceColorsWithRandomPadding(
									[...sideUsedColors, ...image.colors.map((color) => color.code)],
									maxColors,
								);
							}
							return [];
						}
						if (filledImageColors && filledImageColors.length > 0) {
							return filledImageColors.map((color) => color.hex);
						}
						if (sideUsedColors && !!maxColors) {
							return sideUsedColors;
						}
						return [];
					};

					const getColorMappings = async (): Promise<ColorMapping[]> => {
						const image = await getImage(item.filledImageId!);
						if (image.type === 'Vector' || image.format.toLowerCase() === 'svg') {
							if (image.containsRaster || image.isMulticolor || image.colors.length > 100) {
								throw new Error('Image contains more than 100 colors, rasters or gradients.');
							}
						}
						const colorMappings = await getCurrentImageDefaultColorMappings(
							item.filledImageId!,
							printTypeId,
							getColorPaletteToUseForAutoRecoloring(image),
						);
						const paletteColors = printType.paletteColors;

						if (!askUserForRecoloring) {
							return colorMappings;
						}
						if (
							// colori identici
							colorMappings.every((map) => map.src.toLowerCase() === map.dest?.toLowerCase()) ||
							// solo un colore disponibile
							(maxColors === 1 && (sideUsedColors?.length ?? 0) > 0) ||
							(maxColors === 1 && paletteColors.length > 0)
						) {
							return colorMappings;
						}
						return new Promise((resolve, reject) => {
							const onReject = (avoidRedirect?: boolean) => {
								reject(new UserPaletteConfirmError('Adapt Image Closed'));
								if (avoidRedirect) return;
								isMobile() ? setMenuPage({ page: 'none' }) : setMenuPage({ page: 'design-elements' });
							};
							setMenuPage(
								{
									page: 'recolor-image',
									image,
									sideId: item.sideId,
									printTypeId: printTypeId,
									initialColorMappings: colorMappings,
									onContinue: (colorMappings: ColorMapping[]) => {
										resolve(colorMappings);
									},
									onClose: onReject,
								},
								false,
								() => onReject(true),
							);
						});
					};

					if ((filledImageColors && filledImageColors.length > 0) || !!maxColors) {
						const colorMappings = await getColorMappings();

						return {
							...item,
							filledImageColors: colorMappings.map((x) => x.dest),
							filledImageColorMapping: colorMappings,
						};
					}
					return item;
				}
				return item;
			};

			const newItem = { ...item };
			const { colors: imageColors } = getSelectableColorsForImage(item.sideId, null, printTypeId, false, false);
			const sideUsedColors = sideId ? usedColors.get(sideId) : null;

			if (
				!sideUsedColors &&
				printType.paletteColors.includes(newItem.fillColor) &&
				printType.paletteColors.includes(newItem.strokeColor)
			)
				return adaptFilledImage(newItem);

			if (
				sideUsedColors &&
				printType &&
				printType.maxColors &&
				printType.maxColors - 1 <= (sideUsedColors?.length ?? 0)
			) {
				if (!sideUsedColors.slice(0, printType.maxColors).includes(newItem.fillColor))
					newItem.fillColor = sideUsedColors[0];
				if (!sideUsedColors.slice(0, printType.maxColors).includes(newItem.strokeColor))
					newItem.strokeColor = sideUsedColors[0];
			}

			if (imageColors && imageColors.length > 0) {
				const usedFillColor =
					imageColors.find((i) => i.hex === newItem.fillColor)?.hex ??
					getNearestColorInSelectableColors(sideId, newItem.fillColor, printTypeId, false, true);
				const usedStrokeColor =
					imageColors.find((i) => i.hex === newItem.strokeColor)?.hex ??
					getNearestColorInSelectableColors(sideId, newItem.strokeColor, printTypeId, false, true);
				const validUsedColor =
					usedFillColor ?? usedStrokeColor ?? sideUsedColors?.find((hex) => some(imageColors, { hex }));

				if (validUsedColor) {
					newItem.fillColor = usedFillColor ?? validUsedColor;
					newItem.strokeColor = usedStrokeColor ?? validUsedColor;
					return adaptFilledImage(newItem);
				}

				newItem.fillColor = imageColors[0].hex;
				newItem.strokeColor = imageColors[0].hex;
				return adaptFilledImage(newItem);
			}

			return adaptFilledImage(newItem);
		},
		[
			product?.printTypes,
			usedColors,
			getSelectableColorsForImage,
			getCurrentImageDefaultColorMappings,
			getImage,
			setMenuPage,
			getNearestColorInSelectableColors,
		],
	);
};

export const useAdaptTextArtItemToPrintMethods = () => {
	const product = useZakekeProduct();
	const usedColors = useZakekeUsedColors();
	const { getSelectableColorsForText, getNearestColorInSelectableColors } = useZakekeHelpers();

	/**
	 * Adapt an element to a print type by applying the print type rules
	 * @param item The item to adapt
	 * @param printTypeId  The print type id
	 * @param sideId The side id
	 * @returns
	 */
	return useCallback(
		async (item: TextArtElement, printTypeId: number, sideId: number) => {
			const printType = product?.printTypes.find((x) => x.id === printTypeId);

			if (!printType) throw new Error(`Cannot find print type ${printTypeId}`);

			const newItem = { ...item };

			const textColors = getSelectableColorsForText(item.sideId, null, printTypeId, false);
			const sideUsedColors = sideId ? usedColors.get(sideId) : null;

			// change font if not available
			if (!printType.fonts.find((x) => x.id === newItem.fontFamilyId && printType.fonts[0])) {
				newItem.fontFamilyId = printType.fonts[0].id;
				newItem.fontFaceId = printType.fonts[0].faces[0]?.id;
			}

			if (
				!sideUsedColors &&
				printType.paletteColors.includes(newItem.fillColor) &&
				printType.paletteColors.includes(newItem.strokeColor)
			)
				return newItem;

			if (
				sideUsedColors &&
				printType &&
				printType.maxColors &&
				printType.maxColors - 1 <= sideUsedColors?.length
			) {
				newItem.fillColor =
					sideUsedColors.slice(0, printType.maxColors).find((c) => c === newItem.fillColor) ??
					sideUsedColors[0];
				newItem.strokeColor =
					sideUsedColors.slice(0, printType.maxColors).find((c) => c === newItem.strokeColor) ??
					sideUsedColors[0];
			}

			if (textColors && textColors.length > 0) {
				newItem.fillColor =
					textColors.find((color) => color.hex === newItem.fillColor)?.hex ??
					getNearestColorInSelectableColors(sideId, newItem.fillColor, printType.id, false) ??
					textColors[0].hex;
				newItem.strokeColor =
					textColors.find((color) => color.hex === newItem.strokeColor)?.hex ??
					getNearestColorInSelectableColors(sideId, newItem.strokeColor, printType.id, false) ??
					textColors[0].hex;
				return newItem;
			}

			return newItem;
		},
		[getSelectableColorsForText, product?.printTypes, usedColors, getNearestColorInSelectableColors],
	);
};

export const useHandleAdaptItemToPrintMethodsErrors = () => {
	const { showDialog } = useDialogManager();
	const { T } = useZakekeTranslations();

	const handleAdaptItemToPrintMethodsErrors = useCallback(
		(error: unknown) => {
			if (!(error instanceof Error)) return;
			if (error instanceof UserPaletteConfirmError) return;

			console.error('Error adapting image', error);

			if (error.message === 'Image contains more than 100 colors, rasters or gradients.') {
				showDialog('error', <ErrorDialog title={T._('Image not supported')} message={T._(error.message)} />);
				return;
			}
			showDialog('error', <ErrorDialog message={T._('Generic Error')} />);
		},
		[T, showDialog],
	);
	return handleAdaptItemToPrintMethodsErrors;
};

export const useAdaptItemToPrintMethods = (isTextUppercase?: boolean) => {
	const product = useZakekeProduct();
	const adaptImageItemToPrintMethods = useAdaptImageItemToPrintMethods();
	const adaptTextItemToPrintMethods = useAdaptTextItemToPrintMethods(isTextUppercase);
	const adaptShapeItemToPrintMethods = useAdaptShapeItemToPrintMethods();
	const adaptTextArtItemToPrintMethods = useAdaptTextArtItemToPrintMethods();

	/**
	 * Adapt an element to a print type by applying the print type rules
	 * @param item The item to adapt
	 * @param printTypeId  The print type id
	 * @param useCurrentColors If true, the current colors will used when deciding the new colors.
	 * It should be true when adding new items, and false when loading new designs o changing print types
	 * @returns
	 */
	const adaptItemToPrintMethods = useCallback(
		async (
			item: CustomizerElement,
			printTypeId: number,
			sideId: number,
			useCurrentColors: boolean,
			availableColors?: string[],
		) => {
			const printType = product?.printTypes.find((x) => x.id === printTypeId);

			if (!printType) throw new Error(`Cannot find print type ${printTypeId}`);

			const newItem: CustomizerElement = { ...item };

			if (isTextElement(newItem)) {
				return adaptTextItemToPrintMethods(newItem, printTypeId, sideId);
			}
			if (isImageElement(newItem)) {
				return adaptImageItemToPrintMethods(newItem, printTypeId, sideId, useCurrentColors, availableColors);
			}
			if (isShapeElement(newItem)) {
				return adaptShapeItemToPrintMethods(newItem, printTypeId, sideId, useCurrentColors);
			}
			if (isTextArtElement(newItem)) {
				return adaptTextArtItemToPrintMethods(newItem, printTypeId, sideId);
			}

			return newItem;
		},
		[
			adaptImageItemToPrintMethods,
			adaptShapeItemToPrintMethods,
			adaptTextArtItemToPrintMethods,
			adaptTextItemToPrintMethods,
			product?.printTypes,
		],
	);

	return adaptItemToPrintMethods;
};

const useAdaptSideToPrintMethod = () => {
	const adaptItemToPrintMethods = useAdaptItemToPrintMethods();
	const handleAdaptItemToPrintMethodsErrors = useHandleAdaptItemToPrintMethodsErrors();
	const usedColors = useZakekeUsedColors();
	const validateSideForPrintMethod = useZakekeValidateSideForPrintType();
	return async (
		product: Product,
		sidesPrintMethods: Map<number, number>,
		sideAreaBackgrounds: SideAreaBackgrounds,
		selectedVariantId: number,
		items: CustomizerElement[],
		sideId: number,
		printTypeId: number,
		allSides = false,
	) => {
		const actualPrintMethods = Map(sidesPrintMethods);
		const selectedVariant = product.variants.find((x) => x.id === selectedVariantId);
		const side = selectedVariant?.sides.find((x) => x.id === sideId);
		const newPrintMethods = prepareMethods(selectedVariant, actualPrintMethods, sideId, printTypeId, allSides);

		const sidePrintMethod = product?.printTypes.find((x) => x.id === newPrintMethods.get(sideId))!;

		// variasetSidesPrintTypes(newPrintMethods);bles for available colors definition
		let availableColors: string[] = [];
		const maxColors = sidePrintMethod.maxColors;
		const paletteColors = sidePrintMethod.paletteColors;
		const sideUsedColors = sideId ? usedColors.get(sideId) : null;

		// const validation = validateSideForPrintMethod(sideId, printTypeId, false);
		const validation = await validateSideForPrintMethod(sideId, printTypeId, allSides);

		// Apply validation
		let currentItems = items;

		// 1. Remove texts
		currentItems = currentItems.filter((x) => !validation.textItemsWrong.includes(x.guid));

		// 2. Remove images
		currentItems = currentItems.filter((x) => !validation.imageItemsWrong.includes(x.guid));

		// 3. Fix font sizes
		// remove text shadow if not allowed
		currentItems = currentItems.map((item) => {
			if (isTextElement(item)) {
				console.log('item adapt', item);
				const listedFontSize = validation.textItemsWrongSize.includes(item.guid)
					? sidePrintMethod.fontSizeList?.[0]
					: null;
				const shadowColor = sidePrintMethod.isTextShadowsEnabled ? item.shadowColor : null;
				return {
					...item,
					fontSize: listedFontSize ?? item.fontSize,
					shadowColor: shadowColor,
				};
			}

			return item;
		});

		// 4. Fixed images size
		const ppcm = selectedVariant?.sides.find((x) => x.id === sideId)?.ppcm;
		if (ppcm) {
			currentItems = await Promise.all(
				currentItems.map(async (item) => {
					if (isImageElement(item) && validation.imageItemsWrongSize.includes(item.guid)) {
						console.log('adapting image');

						if (item.preferredWidth && item.preferredHeight) {
							const newWidth = (item.preferredWidth * ppcm) / 10;
							const newHeight = (item.preferredHeight * ppcm) / 10;

							return {
								...item,
								width: newWidth,
								height: newHeight,
								replaceHeight: newWidth,
								replaceWidth: newHeight,
							};
						}
					}

					return item;
				}),
			);
		}

		if (paletteColors?.length) {
			maxColors ? (availableColors = paletteColors.slice(0, maxColors)) : (availableColors = paletteColors);
		} else if (sideUsedColors?.length) {
			maxColors && (availableColors = sliceColorsWithRandomPadding(sideUsedColors, maxColors));
		}

		currentItems = (
			await Promise.all(
				currentItems.map(async (item) => {
					if (!allSides && item.sideId !== sideId) return item;
					try {
						return adaptItemToPrintMethods(item, printTypeId, side?.id!, false, availableColors);
					} catch (e) {
						handleAdaptItemToPrintMethodsErrors(e);
						return Promise.resolve(null);
					}
				}),
			)
		).filter((i) => !!i) as CustomizerElement[];

		let newSideAreaBackgrounds = Map(sideAreaBackgrounds);
		if (sideAreaBackgrounds) {
			sideAreaBackgrounds.forEach((side) => {
				side.forEach((area, areaId) => {
					if (availableColors.length && area.fillColor && !includes(availableColors, area.fillColor)) {
						newSideAreaBackgrounds = newSideAreaBackgrounds.set(
							sideId,
							(newSideAreaBackgrounds.get(sideId) || Map()).set(areaId, {
								fillColor: availableColors[0],
								imageItemGuid: null,
							}),
						);
					} else {
						if (!area.imageItemGuid) {
							newSideAreaBackgrounds = newSideAreaBackgrounds.set(
								sideId,
								(newSideAreaBackgrounds.get(sideId) || Map()).set(areaId, {
									fillColor: area.fillColor,
									imageItemGuid: null,
								}),
							);
						}
					}
				});
			});
		}

		return {
			sidePrintMethods: newPrintMethods,
			items: currentItems,
			sideAreaBackgrounds: newSideAreaBackgrounds,
		};
	};
};
const usePrintMethods = (isTextUppercase?: boolean) => {
	const [setSidesPrintTypes, sidesPrintMethods, setSideAreaBackgrounds, sideAreaBackgrounds, setForceResetEnabled] =
		useAppStore((x) => [
			x.setSidesPrintMethods,
			x.sidesPrintMethods,
			x.setSideAreaBackgrounds,
			x.sideAreaBackgrounds,
			x.setForceResetEnabled,
		]);
	const setItems = useSetItems();
	const getItems = useGetItems();
	const side = useSelectedSide();
	const product = useZakekeProduct();
	const selectedVariant = useSelectedVariant();
	const usedColors = useZakekeUsedColors();
	const validateSideForPrintMethod = useZakekeValidateSideForPrintType();

	const getPrintMethodForSide = useGetPrintMethodForSide();
	const adaptItemToPrintMethods = useAdaptItemToPrintMethods(isTextUppercase);
	const handleAdaptItemToPrintMethodsErrors = useHandleAdaptItemToPrintMethodsErrors();
	const adaptSideToPrintMethod = useAdaptSideToPrintMethod();

	const commitState = useUndoRedoCommit();

	const setSidePrintMethod = async (sideId: number, printTypeId: number, allSides = false) => {
		const actualPrintMethods = Map(sidesPrintMethods);
		const newPrintMethods = prepareMethods(selectedVariant, actualPrintMethods, sideId, printTypeId, allSides);
		setSidesPrintTypes(newPrintMethods);
		const sidePrintMethod = product?.printTypes.find((x) => x.id === newPrintMethods.get(sideId))!;

		// variables for available colors definition
		let availableColors: string[] = [];
		const maxColors = sidePrintMethod.maxColors;
		const paletteColors = sidePrintMethod.paletteColors;
		const sideUsedColors = sideId ? usedColors.get(sideId) : null;

		// const validation = validateSideForPrintMethod(sideId, printTypeId, false);
		const validation = await validateSideForPrintMethod(sideId, printTypeId, allSides);

		// Apply validation
		let currentItems = getItems();

		// 1. Remove texts
		currentItems = currentItems.filter((x) => !validation.textItemsWrong.includes(x.guid));

		// 2. Remove images
		currentItems = currentItems.filter((x) => !validation.imageItemsWrong.includes(x.guid));

		// 3. Fix font sizes
		// remove text shadow if not allowed
		currentItems = currentItems.map((item) => {
			if (isTextElement(item)) {
				console.log('item adapt', item);
				const listedFontSize = validation.textItemsWrongSize.includes(item.guid)
					? sidePrintMethod.fontSizeList?.[0]
					: null;
				const shadowColor = sidePrintMethod.isTextShadowsEnabled ? item.shadowColor : null;
				return {
					...item,
					fontSize: listedFontSize ?? item.fontSize,
					shadowColor: shadowColor,
				};
			}

			return item;
		});

		// 4. Fixed images size
		const ppcm = selectedVariant?.sides.find((x) => x.id === sideId)?.ppcm;
		if (ppcm) {
			currentItems = await Promise.all(
				currentItems.map(async (item) => {
					if (isImageElement(item) && validation.imageItemsWrongSize.includes(item.guid)) {
						console.log('adapting image');

						if (item.preferredWidth && item.preferredHeight) {
							const newWidth = (item.preferredWidth * ppcm) / 10;
							const newHeight = (item.preferredHeight * ppcm) / 10;

							return {
								...item,
								width: newWidth,
								height: newHeight,
								replaceHeight: newWidth,
								replaceWidth: newHeight,
							};
						}
					}

					return item;
				}),
			);
		}

		if (paletteColors?.length) {
			maxColors ? (availableColors = paletteColors.slice(0, maxColors)) : (availableColors = paletteColors);
		} else if (sideUsedColors?.length) {
			maxColors && (availableColors = sliceColorsWithRandomPadding(sideUsedColors, maxColors));
		}

		currentItems = (
			await Promise.all(
				currentItems.map((item) => {
					if (!allSides && item.sideId !== sideId) return item;
					return adaptItemToPrintMethods(item, printTypeId, side?.id!, false, availableColors).catch((e) => {
						handleAdaptItemToPrintMethodsErrors(e);
						return Promise.resolve(void 0);
					});
				}),
			)
		).filter((i) => !!i) as CustomizerElement[];

		setItems(() => currentItems, true);

		if (sideAreaBackgrounds) {
			sideAreaBackgrounds.forEach((side) => {
				side.forEach((area, areaId) => {
					if (availableColors.length && area.fillColor && !includes(availableColors, area.fillColor)) {
						setSideAreaBackgrounds(sideId, areaId, availableColors[0], null);
					} else {
						if (!area.imageItemGuid) setSideAreaBackgrounds(sideId, areaId, area.fillColor, null);
					}
				});
			});
		}

		setForceResetEnabled(true);
		commitState({
			resetUndoRedo: true,
		});
	};

	return {
		setSidePrintMethod,
		adaptItemToPrintMethods,
		handleAdaptItemToPrintMethodsErrors,
		adaptSideToPrintMethod,
		getPrintMethodForSide,
	};
};

export default usePrintMethods;
