import { useCallback } from 'react';
import { ProductVariant, useZakekeBulkAndNameNumber } from '@zakeke/zakeke-customizer-react';

const useVariantPricing = () => {
	const { getPrices } = useZakekeBulkAndNameNumber();

	const isVariantOutOfStock = useCallback(
		async (currentVariantId: number, productVariants: ProductVariant[]) => {
			const currentVariant = productVariants.find((variant) => variant.id === currentVariantId);

			if (!currentVariant) return true;
			const attributes = new Map();
			currentVariant.variantCode.forEach((code) => {
				attributes.set(code.Id, code.Value.Id);
			});
			const price = await getPrices([{ attributes, quantity: 1 }]);
			return price[0].isOutOfStock;
		},
		[getPrices],
	);

	return { isVariantOutOfStock };
};

export default useVariantPricing;
